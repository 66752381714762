import wrapURL from '@/components/fedexPickup/FedexPickupData/interceptorWithoutNotification.js';

//this is wrapped by the interceptor without error notification popup
export const fedexPickupBaseURL = wrapURL(
    process.env.VUE_APP_ENV == "production"
    ? "https://www.vibrant-america.com/lisapi/v1/lis/inventory-prod-http" // production
    : "https://www.vibrant-america.com/lisapi/v1/lis/inventory-dev-http" //staging
)

//this is wrapped by the interceptor without error notification popup
export const fedexPickupOrderBaseURL = wrapURL(
    process.env.VUE_APP_ENV=='production' 
    ? 'https://api.vibrant-wellness.com/v1/portal/order' // production
    :'https://api.vibrant-wellness.com/v1/portal/order/staging' //staging
);

export const sampleBaseURL = wrapURL(
    process.env.VUE_APP_ENV == "production" 
    ? "https://www.vibrant-america.com/lisapi/v1/lis/sample" 
    : "https://www.vibrant-america.com/lisapi/v1/lis/sample-dev"
);