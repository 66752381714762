const genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];

const yesNoOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const hysterectomyOptions = [
  {
    label: "Yes- Total",
    value: "TOTAL_HYSTERECTOMY",
  },
  {
    label: "Yes- Partial",
    value: "PARTIAL_HYSTERECTOMY",
  },
  {
    label: "No",
    value: "NO",
  },
];

const ovariesRemovedOptions = [
  {
    label: "Both Ovaries",
    value: "BOTH",
  },
  {
    label: "One Ovary",
    value: "ONE",
  },
  {
    label: "None",
    value: "NO",
  },
];

const menstrualCyclePhaseOptions = [
  {
    label: "Luteal Phase (Days 15 – 28)",
    value: "LUTEAL",
  },
  {
    label: "Follicular Phase (Days 6 – 14)",
    value: "FOLLICULAR",
  },
  {
    label: "Ovulatory Phase (Days 14 – 15) ",
    value: "OVULATORY",
  },
  {
    label: "Pre-puberty (has not had 1st period) ",
    value: "PRE_PUBERTY",
  },
  {
    label:
      "Unable to select Phase due to irregular cycle(s), for less than 1 year, for any reason",
    value: "UNABLE_TO_SELECT",
  },
  {
    label: "No menstrual cycles for greater than 1 year, for any reason",
    value: "NO_MENSTRUAL_CYCLES",
  },
];

const reproductiveHealthStageOptions = [
  {
    label: "Premenopausal Phase(regularly menstruating every 21-35 days) ",
    value: "PREMENOPAUSAL_PHASE",

  },
  {
    label:
      "Perimenopausal Phase(irregular menstruation and transitioning to menopause)",
    value: "PERIMENOPAUSAL_PHASE",
  },
  {
    label: "Postmenopausal Phase(no menstruation for >1 year)",
    value: "POSTMENOPAUSAL_PHASE",
  },
  {
    label: "Using hormone replacement therapy",
    value: "REPLACEMENT_THERAPY",
  },
  {
    label: "Not sure",
    value: "UNSURE",
  },
];

const premenopausalPhaseResultOptions = [
  {
    label: "Menstrual",
    value: "MENSTRUAL",
  },
  {
    label: "Follicular",
    value: "FOLLICULAR",
  },
  {
    label: "Ovulatory",
    value: "OVULATORY",
  },
  {
    label: "Luteal",
    value: "LUTEAL",
  },
];

const yesNoUnsureOptions = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "No",
    value: "NO",
  },
  {
    label: "Unsure",
    value: "UNSURE",
  },
];

const HRTOptions = [
  {
    label: "HRT",
    value: "HRT",
  },
  {
    label: "Non-HRT",
    value: "Non_HRT",
  },
  {
    label: "Unsure",
    value: "UNSURE",
  },
];

const severityOptions = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
];

const femaleDiseaseHistoryOptions = [
  {
    label: "Breast cancer",
    value: "breast_cancer",
  },
  {
    label: "Ovarian cancer",
    value: "ovarian_cancer",
  },
  {
    label: "Endometrial(uterine) cancer",
    value: "endometrial_cancer",
  },
  {
    label: "Stomach cancer",
    value: "stomach_cancer",
  },
  {
    label: "Pancreatic cancer",
    value: "pancreatic_cancer",
  },
  {
    label: "Colon or rectal cancer",
    value: "colon_rectal_cancer",
  },
  {
    label: "Any other condition not listed above",
    value: "other",
  },
];

const femaleComorbidityOptions = [
  {
    label: "Cardiovascular disease",
    value: "cardiovascular_disease",
  },
  {
    label: "Type 2 diabetes",
    value: "type2_diabetes",
  },
  {
    label: "Hypertension",
    value: "hypertension",
  },
  {
    label: "Obesity",
    value: "obesity",
  },
  {
    label: "Thyroid conditions",
    value: "thyroid_conditions",
  },
  {
    label: "Liver disease",
    value: "liver_disease",
  },
  {
    label: "Gallbladder complications",
    value: "gallbladder_complications",
  },
  {
    label:
      "Blood clots or venous thromboembolism (e.g., deep vein thrombosis, pulmonary embolism)",
    value: "blood_clots",
  },
  {
    label: "Other",
    value: "other",
  },
];

const femaleFamilyHistoryOptions = [
  {
    label: "Cancer",
    value: "family_cancer_history",
  },
  {
    label: "Breast cancer",
    value: "family_breast_cancer",
  },
  {
    label: "Hypertension",
    value: "family_hypertension",
  },
  {
    label: "Cardiovascular disease",
    value: "family_cardio_disease",
  },
  {
    label: "Cerebrovascular disease",
    value: "family_cerebrovascular",
  },
  {
    label: "SLE or Autoimmune",
    value: "family_autoimmune",
  },
  {
    label: "Venous thrombus embolism",
    value: "family_venous_thrombus",
  },
  {
    label: "Thyroid disease",
    value: "family_thyroid_disease",
  },
  {
    label: "Other",
    value: "other",
  },
];

const maleFamilyHistoryOptions = [
  {
    label: "Cancer",
    value: "family_cancer_history",
  },
  {
    label: "Prostate cancer/issues",
    value: "family_prostate_cancer",
  },
  {
    label: "Hypertension",
    value: "family_hypertension",
  },
  {
    label: "Cardiovascular disease",
    value: "family_cardio_disease",
  },
  {
    label: "Cerebrovascular disease",
    value: "family_cerebrovascular",
  },
  {
    label: "SLE or Autoimmune",
    value: "family_autoimmune",
  },
  {
    label: "Venous thrombus embolism",
    value: "family_venous_thrombus",
  },
  {
    label: "Thyroid disease",
    value: "family_thyroid_disease",
  },
  {
    label: "Other",
    value: "other",
  },
];

const maleDiseaseHistoryOptions = [
  {
    label: "Type 2 diabetes",
    value: "type2_diabetes",
  },
  {
    label: "Liver disease",
    value: "liver_disease",
  },
  {
    label: "Sleep apnea",
    value: "sleep_apnea",
  },
  {
    label: "SLE or Autoimmune",
    value: "autoimmune_conditions",
  },
  {
    label: "Infertility issues",
    value: "infertility_issues",
  },
  {
    label: "Obesity",
    value: "obesity",
  },
  {
    label: "Cardiovascular disease (heart attack, stroke, etc.)",
    value: "cardiovascular_disease",
  },
  {
    label: "Prostate conditions (e.g., BPH, prostate cancer)",
    value: "prostate_conditions",
  },
  {
    label: "Thyroid disease",
    value: "thyroid_conditions",
  },
  {
    label: "Hypertension",
    value: "hypertension",
  },
  {
    label: "Other",
    value: "other",
  },
];

const boneDensityScanOptions = [
  {
    label: "Normal",
    value: "NORMAL",
  },
  {
    label: "Osteopenia",
    value: "OSTEOPENIA",
  },
  {
    label: "Osteoporosis",
    value: "OSTEOPOROSIS",
  },
  {
    label: "Don't know",
    value: "UNKNOWN",
  },
];

export {
  genderOptions,
  yesNoOptions,
  hysterectomyOptions,
  ovariesRemovedOptions,
  menstrualCyclePhaseOptions,
  reproductiveHealthStageOptions,
  yesNoUnsureOptions,
  HRTOptions,
  severityOptions,
  femaleDiseaseHistoryOptions,
  femaleComorbidityOptions,
  femaleFamilyHistoryOptions,
  maleFamilyHistoryOptions,
  maleDiseaseHistoryOptions,
  boneDensityScanOptions,
  premenopausalPhaseResultOptions,
};
