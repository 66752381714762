<template>
    <div class = "shipping-address-view" v-loading = 'loading'>
        <div v-if = "!loading">
            <BasicInformationView v-if = "is_editable && !edits_saved && !show_contact_support_to_solve_duplicated_patient"/>
            <ShippingAddressInputsView v-if = "is_editable && !edits_saved && address_needed!=false && !show_contact_support_to_solve_duplicated_patient" />
            <ChangeAddressStatusView v-if = '!is_editable || edits_saved || show_contact_support_to_solve_duplicated_patient' />
            <OpsButtonsBar v-if = "is_editable && !edits_saved && !show_contact_support_to_solve_duplicated_patient"/>
        </div>
    </div>

</template>

<script>
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { useCommonStore } from "@/stores/CommonStore.ts";
import ShippingAddressInputsView from './ShippingAddressInputsView.vue';
import ChangeAddressStatusView from './ChangeAddressStatusView.vue';
import BasicInformationView from "@/components/shippingAddress/BasicInformationView.vue";
import OpsButtonsBar from '@/components/shippingAddress/OpsButtonsBar.vue';
// import { env_version } from '@/components/shippingAddress/shippingAddressData/shippingAddressMockData.js';
import { mapState, mapActions } from 'pinia';
import { ElNotification } from 'element-plus';
export default{
    components:{
        ShippingAddressInputsView,
        ChangeAddressStatusView,
        BasicInformationView,
        OpsButtonsBar,
    },
    setup(){
        const shippingAddressStore = useShippingAddressStore();
        return {
            shippingAddressStore,
        }
    },
    async mounted(){
        //start loading
        this.shippingAddressStore.loading = true;
        // ------------ remove the logic with unsecured link (Might cause privacy expose) ----------------------
        // console.log('env_version:', process.env, process.env.VUE_APP_ENV);
        // this.setAccessionId(this.$route.query.accession_id);
        // this.setSampleId(this.$route.query.sample_id);
        // this.storeAllQueryInfomation(this.$route.query);
        // ------------ remove the logic with unsecured link (Might cause privacy expose) END ----------------------

        // ------------ the redirect feature if refresh at /informationLanding ----------------------
        //if token, sample_id or accession_id in ShippingAddressStore are null or empty, means user refresh at the current page:
        // -> need to get the query from the localStorage, and reset variables in store
        if(!this.sample_id || !this.accession_id || !this.token){
            console.log('getLocalStorageQuery shipping_page_query',localStorage.getItem('shipping_page_query'));
            try{
                await this.storeAllQueryInfomation(JSON.parse(localStorage.getItem('shipping_page_query')));
            }catch(err){
                console.log('error in shippingAddressView mounted:',err);
                this.shippingAddressStore.loading = false;
                this.$router.push('/failed');
                return;
            }
        }
        // call the api of fetching all information needed and the api of checking whether the shipping address is confirmed or saved before at the same time:
        // NOTES: allSettled: won't throw error if one of the promises is rejected, but will return the status of the promise.
        try{
            const results = await Promise.allSettled([
                this.getBasicInformationAndShippingAddressInfo(),
                this.isShippingAddressConfirmedOrSavedBefore(),
            ]);
            console.log('results:',results);
            // -> if get basic information and shipping address info failed, redirect to /failed, since the basic information and shipping address info affects the main flow.
            if(results[0].status === 'rejected'){
                this.shippingAddressStore.loading = false;
                ElNotification({
                    title: 'Failed',
                    type: 'error',
                    customClass: "errorNotification",
                    message: results?.[0]?.reason?.response?.data?.message ?? 'Failed to get basic information and shipping address.',
                    duration: 0,
                });
                this.$router.push('/failed');
                return;
            }
            // -> if isShippingAddressConfirmedOrSavedBefore failed, won't affect main flow, show the error message and keep the user at the current page.
            if(results[1].status === 'rejected'){
                ElNotification({
                    title: 'Failed',
                    type: 'error',
                    customClass: "errorNotification",
                    message: results?.[1]?.reason?.response?.data?.message ?? 'Failed to check whether the shipping address is confirmed or saved before.',
                    duration: 0,
                });
            }
        }catch(err){
            console.log('error in shippingAddressView mounted:',err);
            this.shippingAddressStore.loading = false;
            ElNotification({
                title: 'Error',
                type: 'error',
                customClass: "errorNotification",
                message: err?.response?.data?.message ?? 'Failed to get basic information and shipping address related information.',
                duration: 0,
            });
            this.$router.push('/failed');
            return;
        }
        // getPatientProfileAddress() won't affect loading as it shouldn't affect the mean flow. So, we can do it secretly after loading while the user inputs the information.
        // NOTES: Only call if get patient_id from localStorage. If not, redirect to general failed page. It's used for whether shown notes to inform patient that their input is the same as the profile info.
        if(this.patient_id){
            this.getPatientProfileAddress();  
        }else{
            console.log('cannot get patient_id in shippingAddressView mounted');
            this.shippingAddressStore.loading = false;
            ElNotification({
                title: 'Error',
                type: 'error',
                customClass: "errorNotification",
                message: 'Failed to get patient address on profile.',
                duration: 0,
            });
            this.$router.push('/failed');
            return;
        }
        this.shippingAddressStore.loading = false;
    },
    computed:{
        ...mapState(useShippingAddressStore,[
            'sample_id',
            'accession_id',
            'patient_id',
            'token',
            'is_editable',
            'edits_saved',
            'loading',
            'previous_shipping_address_data',
            'valid_concierge_states',
            'direct_to_blooddraw',
            'is_kit_delivered',
            'address_needed',
            'incomplete_address_in_db',
            'show_contact_support_to_solve_duplicated_patient',
        ]),
    },
    watch:{
        incomplete_address_in_db(newVal){
            if(newVal!=null){
                this.shippingAddressStore.is_apply_to_future_order = newVal;
                if(newVal){
                    console.log('0-1');
                    //matomo tracking 0-1 visiting
                    this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','0-1-visiting', 1);
                }else{
                    console.log('1-1.1');
                    //matomo tracking 1-1.1 visiting
                    this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-visiting', 1);
                }
            }
            console.log('incomplete_address_in_db change', newVal);
        },
        is_kit_delivered(newVal){
            //if is_editable is false, the kit is delivered
            if(newVal && this.direct_to_blooddraw){
                this.goToNextStep(this.$router);
            }
        }
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'storeAllQueryInfomation',
            'getBasicInformationAndShippingAddressInfo',
            'isShippingAddressConfirmedOrSavedBefore',
            'getPatientProfileAddress',
            'updateIsConciergeValid',
            'goToNextStep'
        ]),
        ...mapActions(useCommonStore, [
            'setAccessionId',
            'setSampleId',
        ])
    }
}
</script>

<style scoped>
.shipping-address-view{
    min-height: calc(100vh - 62px);
    padding-bottom: 114px;
}
@media only screen and (max-width: 767px){
    .shipping-address-view{
        padding-bottom: 104px;
    }
}
</style>