<template>
  <div class="questionnaireHeader">
    <div class="questionnaireTitle">Hormone Zoomer Questionnaire</div>
    <p class="text-center">
      To ensure your report comments are appropriate and your background is indicated to the provider accurately, please complete this questionnaire. 
    </p>
    <div class="importantNote">
      <img
        class=" mr-[10px]"
        alt="Important Note"
        src="@/assets/questionnaire/round_warning_mark.svg"
      />
      <p>
        <b>Important note:</b>  If you have filled out the emailed digital version of the questionnaire then you DO NOT have to complete this printed version that comes along with your kit. Failing to fill the questionnaire either online or in print could delay your final report and result in incorrect reference ranges.
      </p>
    </div>
    <p class='thanksMessage'>Thank you for choosing Vibrant for your health journey!</p>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireHeader",
};
</script>

<style scoped>
.questionnaireHeader {
  width: 840px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.questionnaireTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.175px;
  color: #0e4672;
  margin-bottom: 18px;
}

.questionnaireHeader p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.175px;
  color: #0e4672;
}

.importantNote {
  background: #faece6;
  border-radius: 4px;
  min-height: 65px;
  height: fit-content;
  margin: 18px 0;
  padding: 12px;
  display: flex;
  align-items: center;
}

.importantNote b {
  font-weight: 700;
}

@media only screen and (max-width: 808px) {
  .questionnaireHeader {
    width: 358px;
  }

  .questionnaireTitle {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .questionnaireHeader p {
    font-size: 12px;
  }

  .importantNote {
    min-height: 65px;
    margin: 12px 0;
    padding: 8px;
  }
  
  .thanksMessage {
    display: none;
  }
}
</style>
