import { defineStore } from "pinia";
import { blood_draw_mock_data } from "@/components/bloodDraw/BloodDrawData/mockData";
import axios from "axios";
import { baseURL} from '@/components/bloodDraw/BloodDrawData/request.js';
import { orderBaseURL } from "@/utils/orderRequest.js";
import { beta_launch_customer_ids } from "@/utils/betaLaunchData.js";


export const useBloodDrawStore = defineStore('BloodDrawStore',{
    state:()=>{
        return{
            blood_draw_data: null,
            accession_id:null,
            customer_id:null,
            sample_id:null,
            patient_id:null,
            token:null,
            is_concierge_valid:null,
            is_kit_delivered:null,
            is_blood_concierge_beta_launch:null,

            cur_active_tab:'',
            country_options_for_phone:[],
            geo_info_of_selected_location:null,
            location_info_of_selected_location:null,
            page_loading:false,
        }
    },
    actions:{
        async getBloodDrawData(){
            console.log('call blood draw api to get data...');
            try{
                const response = await baseURL.get('/utility/getBloodDrawInfo',{
                    headers:{ 
                        Authorization: `Bearer ${this.token}`
                    },
                    params:{
                        customer_id:this.customer_id,
                        sample_id: this.sample_id,  
                    },
                });
                const data = response.data;
                // console.log('convertTabsOrder', response);
                this.convertTabsOrder(data);
                this.blood_draw_data = data;
                //if cannot get is_concierge_valid from url, then use the value get from backend
                if(this.is_concierge_valid==null){
                    this.is_concierge_valid = data.is_valid_concierge;
                }
            }catch(err){
                console.log('getBloodDrawInfo api error:', err);
                this.blood_draw_data=null;
                this.is_concierge_valid = false;
                return Promise.reject(err);
            }
            // this.blood_draw_data = blood_draw_mock_data;
        },
        convertTabsOrder(data){
            const tabs_order_dict = {
                'INOFFICE':'in_office',
                'DRAW-SITE': 'draw_site',
                'MOBILE':'mobile',
            }
            //handle tabs_order == null, when this customer has no pns setting
            const converted_tabs_order = data['tabs_order']==null?['mobile','in_office','draw_site']:data['tabs_order'].map((item)=>{
                return tabs_order_dict[item];
            })
            data['tabs_order'] = converted_tabs_order;
            // console.log('convertTabsOrder', data['tabs_order']);
        },
        getCountryOptions(){
            console.log('getCountryOptions', `Bearer ${this.token}`);
            orderBaseURL.get('/orderTest/getCountryList',{
                headers:{ 
                    Authorization: `Bearer ${this.token}`
                }
            }).then((res)=>{
                console.log('get getCountryOptions res');
                this.country_options_for_phone = res.data;
            }).catch((err)=>{
                console.log('getCountryOptions api error:', err);
            })
        },
        storeAllQueryInfomation(query){
            //temoprary add for debug in main
            console.log('storeAllQueryInfomation', query);
            if(query){
                this.accession_id = query.accession_id;
                this.customer_id = query.customer_id;
                this.sample_id = query.sample_id;
                this.token = query.token;
                this.patient_id = query.patient_id;
                this.is_concierge_valid = query.is_concierge_valid?query.is_concierge_valid=='true':null;
                // Default to be true. 
                // Since if kits provided by provider (from payment page to bloodDraw page), whether to show concierge only depends on is_concierge_valid
                // and the url won't have is_kit_delivered as the param under the above case.
                this.is_kit_delivered = query.is_kit_delivered?query.is_kit_delivered=='true':true;
            }else{
                console.log('no query params');
                // if no query params: reject error
                return Promise.reject('No query params');
            }
            // get info from localStorage
            if(localStorage.getItem('beta_program_enabled')!='undefined' && localStorage.getItem('beta_program_enabled')!=null && localStorage.getItem('beta_programs')!=null && localStorage.getItem('beta_programs')!='undefined'){
                const beta_program_enabled = localStorage.getItem('beta_program_enabled') == 'true';
                const beta_programs = localStorage.getItem('beta_programs');
                if(beta_program_enabled && beta_programs.includes('blood_concierge')){
                    this.is_blood_concierge_beta_launch = true;
                }
            }else{
                this.is_blood_concierge_beta_launch =  beta_launch_customer_ids.includes(this.customer_id);
            }
        },
        setCurActiveTab(val){
            // console.log('setCurActiveTab', val)
            this.cur_active_tab = val;
        },
        storeSelectedLocationGeoInfo(geo_info){
            // console.log('storeSelectedLocationGeoInfo', geo_info);
            this.geo_info_of_selected_location = geo_info;
        },
        storeSelectedLocationInfo(location_name, address){
            // console.log('storeSelectedLocationGeoInfo', geo_info);
            this.location_info_of_selected_location ={
                location_name: location_name,
                address:  address,
            };
        },
        async sendInfoToMyPhone(phone_number,location_name, address){
            const params ={
                phone_number:phone_number,
                text:`Blood Draw Site Name: ${location_name==null||location_name==''?'No Information':location_name}\nAddress: ${address==null||address ==''?'No Information':address}`,
            }
            try{
                const response = await baseURL.get('/utility/sendLocationText',{
                    headers:{ 
                        Authorization: `Bearer ${this.token}`
                    },
                    params:params,
                })
                return response.data.result == 'success';
                // return false;
            }catch(err){
                console.log('sendToMyPhone api error:', err);
                return false;
            }
        }

    },
});