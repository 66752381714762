<template>
  <div
    id="page-container"
    v-loading="loadingBillingInfo"
    element-loading-background="white"
  >
    <div class="sourceContainer" v-if="!loadingBillingInfo">
      <div class="orderSource">
        Order from {{ provider_name }}, {{ clinic_name }}
      </div>
    </div>
    <div class="pageBodyContainer" v-if="!loadingBillingInfo">
      <PaymentOverview :payFixedAmount="payFixedAmount" />
      <!-- the concierge service checkbox isn't available if enter from LIS payment -->
      <!-- avaivable if bloodDraw, address valid, beta list -->
      <ConciergeCheckbox v-if = "!payFixedAmount && blood_draw_needed && is_valid_concierge && blood_concierge_beta_1 && is_currency_usd" v-model = "paymentStore.has_concierge_service"/>
      <div class="paymentInfo">
        <div class="paymentInfoHeader">Payment Information</div>
        <div class="headerDivider" />
        <div class="subpartContainer">
          <div class="paymentSubtitle">Payment Method</div>
          <PaymentRadio
            v-if="!payFixedAmount"
            :options="adjusted_payment_options"
            @onChange="handlePaymentMethodChange"
            :isPaymentMethod="true"
            :preSelectValue="this.count >= 3 && enable_bnpl_method ? 'payLater' : ''"
          />
          <PaymentRadio
            v-else
            :options=" adjusted_internal_payment_options"
            @onChange="handlePaymentMethodChange"
          />
        </div>
        <BnplContainer v-if="paymentMethod == 'payLater'" />
        <!-- CardPayContainer means pay with Stax card platform -->
        <CardPayContainer v-if="paymentMethod == 'card' && !provider_has_revenue && !is_international_payment" />
        <!-- HSACardPayContainer means pay with Stripe card platform, can switch between stax and stripe-->
        <HsaCardPayContainer v-if="paymentMethod == 'card' && (provider_has_revenue || is_international_payment)" :isHSACard="false" />
        <HsaCardPayContainer v-if="paymentMethod == 'hsa/fsa'" :isHSACard="true"/>
        <!-- <DigitalWalletContainer v-show="paymentMethod == 'digitalWallet'" /> -->
      </div>
      <img id="footerImage" alt="Footer Image" src="@/assets/footerImage.svg" />
    </div>
  </div>
</template>

<script>
import PaymentRadio from "@/components/payment/PaymentRadio.vue";
import PaymentOptions from "@/utils/paymentMethodOptions.js";
import InternalPaymentOptions from "@/utils/InternalPaymentMethodOptions.js";
import PaymentOverview from "@/components/payment/PaymentOverview.vue";
import ConciergeCheckbox from '@/components/payment/ConciergeCheckbox.vue';
import BnplContainer from "@/components/payment/BnplContainer.vue";
import CardPayContainer from "@/components/payment/CardPayContainer.vue";
import HsaCardPayContainer from "@/components/payment/HsaCardPayContainer.vue";

import { mapState, mapActions } from "pinia";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { ElNotification } from "element-plus";

export default {
  name: "PaymentView",
  components: {
    PaymentRadio,
    PaymentOverview,
    BnplContainer,
    CardPayContainer,
    HsaCardPayContainer,
    ConciergeCheckbox,
    // DigitalWalletContainer,
  },
  setup(){
    const paymentStore = usePaymentStore();
    return{
      paymentStore
    }
  },
  data() {
    return {
      paymentMethod: "",
      PaymentOptions,
      InternalPaymentOptions,
      loadingBillingInfo: true,
      payFixedAmount: localStorage.getItem("amount_to_pay") ? true : false,
      is_markup_order: false,
      provider_has_revenue:false,
      case_insensitive_domestic_name_collection:[
        'US',
        'UNITED STATES',
        'THE UNITED STATES',
        'UNITED STATE',
        'THE UNITED STATE',
        'AMERICA',
        'U.S.',
        'U.S'
      ]
    };
  },
  async created() {
    window.scrollTo(0, 0);
    try {
      const results = await Promise.all([
        this.getBillingInfo(),
        this.fetchClinicInfo(),
        // this.fetchPatientAddressInfo(),
        this.fetchCustomerInfo(),
        this.fetchCustomizedConsultationFeeNameAndCustomizedPNSLogo(),
      ]);
      // temporary use for blood concierge beta 1 launch - blood_concierge
      this.checkWhetherBloodConciergeBeta1();
      // temporary use for blood concierge beta 1 launch - blood_concierge --- end
      const patientAddressInfo = await this.fetchPatientAddressInfo();
      //definitely call after fetchBillingInfo(), as it needs to use billingInfo.currency
      await this.fetchSupportedBnplMethods();
      let billingInfo = results[0];
      //initiate the has_concierge_service when enter payment page
      //if billingInfo's concierge_fee field is not '0.00' or '0':backend_has_concierge_service = true and has_concierge_service = true;
      //else: backend_has_concierge_service = false and has_concierge_service = false;
      if(Number(billingInfo.concierge_fee)!=0){
        this.setBackendHasConciergeService(true);
        this.setHasConciergeService(true);
      }else{
        this.setBackendHasConciergeService(false);
        this.setHasConciergeService(false);
      }
      this.is_markup_order = billingInfo.is_markup_order;
      this.provider_has_revenue = billingInfo.provider_has_revenue;
      this.setIsMarkupOrder(this.is_markup_order);
      this.setProviderHasRevenue(this.provider_has_revenue);
      this.paymentStore.is_international_payment = patientAddressInfo.country 
      ? 
      !this.case_insensitive_domestic_name_collection.includes(patientAddressInfo.country.toUpperCase())
      :
      (
        results[2].countryName
        ?
        !this.case_insensitive_domestic_name_collection.includes(results[2].countryName.toUpperCase())
        :
        false
      );
      console.log('mark up, has revenue, and international', this.is_markup_order, this.provider_has_revenue, this.is_international_payment);
      let accessionId = results[1].accessionId;
      let customerId = results[1].customerId;
      let sampleId = results[1].sampleId;
      let chargeMethod = results[1].chargeMethod;
      let patientId = results[1].patientId;
      let addressCheckNeeded = results[1].addressCheckNeeded;
      let bloodDrawNeeded = results[1].bloodDrawNeeded;
      let token = localStorage.getItem("pnsToken");
      this.addPatientIDToMatomo(patientId);
      // set user id (patient_id) for Sentry
      this.addPatientIDToSentry(patientId);
      // set user id (patient_id) for full story
      this.addPatientIDToFullStory(patientId);
      // this.$matomo && this.$matomo.setUserId(this.patientId);
      let directToBlooddraw =
        localStorage.getItem("direct_to_blooddraw") == "true";
      // if the url query has always_show_payment=true:
      // -> ads for concierge, if is_valid_concierge: always show at /payment_form with concierge checkbox
      // NOTE: only run the following direct redirect logic, if: 
      // 1. balance is 0
      // 2. && (not (from concierge_ads_link && haven't add concierge service)), since if add concierge and balance 0, it means concierge_paid.
      //    if concierge paid, balance 0 and from concierge_ads_link: run redirect logic.
      // check if the order balance is 0,
      if (Number(billingInfo.total) <= 0 && !(this.from_concierge_ads_link && !this.has_concierge_service)) {
        // if the order is not patient pay later and not (from concierge_ads_link && concierge is paid),  or indicate to directToBloodDraw: don't show payment_succeess, directly go next page 
        // * NOTE: (from concierge_ads_link && concierge is paid) is considered as a way of patient pay later.
        if ((chargeMethod != "patientPayLater" && !(this.from_concierge_ads_link && this.has_concierge_service)) || directToBlooddraw) {
          // express v2 flow: definitely redirect to shipping. either confirm basic information or confirm both basic information and shipping address.
          this.$router.push(
            `uploadShippingAddress?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&bloodDrawNeeded=${bloodDrawNeeded}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}&address_needed=${addressCheckNeeded}`
          );
          // ------- previous flow: conditionally redirect to different pages (temporary save as record) ----------
          // if (addressCheckNeeded) {
          //   // const redirectUrl = `https://pns-singlepage.pages.dev/uploadShippingAddress?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&bloodDrawNeeded=${bloodDrawNeeded}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`;
          //   // window.location.href = redirectUrl;
          //   this.$router.push(
          //     `uploadShippingAddress?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&bloodDrawNeeded=${bloodDrawNeeded}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`
          //   );
          //   return;
          //   // if the order is shipped to phlebotomist
          // } else {
          //   // if the order is blood draw, push to blood_draw page
          //   if (bloodDrawNeeded) {
          //     // const redirectUrl = `https://pns.vibrant-wellness.com/bloodDraw?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`;
          //     // window.location.href = redirectUrl;
          //     this.$router.push(
          //       `bloodDraw?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`
          //     );
          //     return;
          //   } else {
          //   // if the order is nonblood draw, push to nonblood_draw tutorial
          //   // NOTE: currently it is not possible that directly go to nonblood_draw tutorial from payment
          //   // since all nonblood kits need to be sent to patient, they will all run under if(addressCheckNeeded){} above.
          //     window.location.href =
          //       "https://www.vibrant-wellness.com/collection/";
          //     return;
          //   }
          // }
          // ------- previous flow: conditionally redirect to different pages (temporary save as record) ---------- end

          // if the order is paid by patient and balance is 0
        } else {
          this.$router.push("/payment_succeed");
        }
      }
      this.loadingBillingInfo = false;
    } catch (err) {
      this.loadingBillingInfo = false;
      console.log(err);
      this.$router.push("/failed");
    }
  },
  computed: {
    ...mapState(usePaymentStore, [
      'billingInfo',
      'supported_bnpl_methods_data',
      "clinic_name",
      "provider_name", 
      "count",
      'is_international_payment',
      'backend_has_concierge_service',
      'has_concierge_service',
      'is_valid_concierge',
      'blood_draw_needed',
      "always_show_payment",
      // temporary use for blood concierge beta 1 launch - blood_concierge
      'blood_concierge_beta_1',
      // temporary use for blood concierge beta 1 launch - blood_concierge ------ end
    ]),
    from_concierge_ads_link(){
      return this.always_show_payment && this.is_valid_concierge && this.blood_concierge_beta_1;
    },
    is_currency_usd(){
      return this.billingInfo && this.billingInfo.currency == 'usd';
    },
    enable_bnpl_method(){
      return this.supported_bnpl_methods_data
      ? this.supported_bnpl_methods_data.klarna_eligible || this.supported_bnpl_methods_data.afterpay_clearpay_eligible
      : false;
    },
    adjusted_payment_options(){
      var output = [{ label: 'Credit / Debit Card' , value: 'card' }];
      if(this.enable_bnpl_method){
        output.push({ label: 'Buy Now Pay Later', value: 'payLater' });
      }
      if(this.is_currency_usd){
        output.push ({ label: 'HSA/FSA Card', value: 'hsa/fsa' });
      }
      console.log('adjusted_payment_options', output);
      return output;
    },
    adjusted_internal_payment_options(){
      var output = [{ label: 'Credit / Debit Card' , value: 'card' }];
      if(this.is_currency_usd){
        output.push ({ label: 'HSA/FSA Card', value: 'hsa/fsa' });
      }
      console.log('adjusted_internal_payment_options', output);
      return output;
    },
  },
  watch:{
    has_concierge_service(newVal){
      console.log('has_concierge_service change', newVal);
      // 1. if has_concierge_service change, we call handleUpdateConciergeServiceViaBackend() to trigger 'updateConciergeServiceViaBackend() and update related store states (has_concierge_service and backend_has_concierge_service)' with whole page loading
      this.handleUpdateConciergeServiceViaBackend(newVal);
    },
  },
  methods: {
    ...mapActions(useCommonStore,[
      'addPatientIDToSentry',
      'setShowVWLoadingAnimation',
    ]),
    ...mapActions(usePaymentStore, [
      "fetchClinicInfo", 
      "getBillingInfo", 
      "setIsMarkupOrder",
      "setProviderHasRevenue",
      'fetchPatientAddressInfo',
      'fetchCustomerInfo',
      'fetchSupportedBnplMethods',
      'fetchCustomizedConsultationFeeNameAndCustomizedPNSLogo',
      'updateConciergeServiceViaBackend',
      'setHasConciergeService',
      'setBackendHasConciergeService',
      // temporary use for blood concierge beta 1 launch - blood_concierge
      'checkWhetherBloodConciergeBeta1'
    ]),
    async handleUpdateConciergeServiceViaBackend(has_concierge_service){
      this.setShowVWLoadingAnimation(true);
      try{
        const res = await this.updateConciergeServiceViaBackend();
        //NOTES: if update data in backend success, the response of updateConciergeServiceViaBackend will be 
        // {
        //   success:true,
        //   real_operation:true or false  
        // }
        // 'real_operation:true' means that the frontend actually sends the request.
        // 'real_operation:false' means that no need to call backend since backend_has_concierge_service == has_concierge_service
        // if update failed: throw an error

        if(res && res.success){
        // 2. if the update is successful:
          // 2.1 update the backend_has_concierge_service (already update inside updateConciergeServiceViaBackend())
          // 2.2 update the has_concierge_service    
          this.paymentStore.has_concierge_service = has_concierge_service;
          // 2.3 update the is_real_update_concierge_service: record whether the recent update concierge service operation is real or not
          this.paymentStore.is_real_update_concierge_service = res.real_operation;   
        }
      }catch(err){
      // 3. if the update is failed:
        // 3.1 reset the has_concierge_service back to backend_has_concierge_service
        this.paymentStore.has_concierge_service = this.backend_has_concierge_service;
        ElNotification({
            title: 'Failed',
            type: 'error',
            customClass: "errorNotification",
            message: err?.response?.data?.message ?? "Failed to add/cancel concierge service.",
            duration: 0,
        });
      }
      this.setShowVWLoadingAnimation(false);
    },
    isTestCustomerId(customerId) {
      return (
        customerId == 999997 ||
        customerId == 29520 ||
        customerId == 29818 ||
        customerId == 31157 ||
        customerId == 12005 ||
        customerId == 1000002
      );
    },
    handlePaymentMethodChange(value) {
      this.paymentMethod = value;
    },
    addPatientIDToFullStory(patientId){
      if (patientId) {
        let attempts = 0;
        const MAX_ATTEMPTS = 10;

        const intervalId = setInterval(() => {
            try {
                if (window.FS) {
                    window.FS('setIdentity', {
                        uid: patientId.toString(),
                        properties: {
                            displayName: 'patient_' + patientId.toString()
                        }
                    });
                    clearInterval(intervalId);
                }

                if (++attempts >= MAX_ATTEMPTS) {
                    console.log('addPatientIDToFullStory: Max attempts reached.');
                    clearInterval(intervalId);  // Prevent infinite loop
                }
            } catch (error) {
                console.log('addPatientIDToFullStory error:', error);
                clearInterval(intervalId);
            }
        }, 500);
      }
    },
    addPatientIDToMatomo(patientId) {
      let width = window.innerWidth;
      let deviceType;
      if (width <= 767) {
        deviceType = 3;
      } else if (width <= 1024) {
        deviceType = 2;
      } else {
        deviceType = 1;
      } 
      if (patientId) {
        const intervalId = setInterval(() => {
          if (this.$matomo) {
            this.$matomo.setUserId(patientId);
            this.$matomo.trackEvent(
              "pns",
              "payment-page",
              "pns-login",
              patientId
            );
            this.$matomo.trackEvent(
              "pns",
              "payment-page",
              "pns-login-device",
              deviceType
            );
            clearInterval(intervalId);
          }
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
/* :deep(.el-loading-spinner) {
  top: 20%;
} */
#page-container {
  min-height: calc(100vh - 62px);
}

.sourceContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.orderSource {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.52px;
  color: #2e557b;
  width: 56vw;
  margin-top: 10px;
}

.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
  height: 1700px;
  position: relative;
}

.paymentInfo {
  width: 56vw;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.paymentInfoHeader {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.51px;
  color: #2e557b;
}

.subpartContainer {
  margin-bottom: 24px;
}

.headerDivider {
  border: 1px solid #bcccdc;
  margin: 16px 0;
}

@media only screen and (max-width: 1200px) {
  .orderSource {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  #footerImage {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .orderSource {
    width: 90%;
    font-size: 12px;
    margin-top: 10px;
  }
  .paymentInfo {
    width: 90%;
    margin-top: 20px;
  }

  .paymentInfoHeader {
    font-size: 16px;
  }

  .headerDivider {
    margin: 12px 0;
  }

  .subpartContainer {
    margin-bottom: 12px;
  }
}
</style>
