<template>
    <div class = "shipping-address-view" v-loading = 'loading'>
    </div>
</template>

<script>
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapActions } from 'pinia';
export default{
    async mounted(){
        this.loading = true;
        //comment for staging test: more convenient to track the redirect link's data;
        console.log('shippingAddressView link', this.$route.query, this.$route.query && Object.keys(this.$route.query).length > 0);
        if(this.$route.query && Object.keys(this.$route.query).length > 0){
            console.log('this.$route.query', this.$route.query);
            try{
                //set data in CommonStore: for the top header information
                this.setAccessionId(this.$route.query.accession_id);
                this.setSampleId(this.$route.query.sample_id);
                //set data in ShippingAddressStore: for related apis after redirect to /informationLanding
                await this.storeAllQueryInfomation(this.$route.query);
                //save query info in localStorage: for user refresh on /informationLanding
                localStorage.setItem('shipping_page_query',JSON.stringify(this.$route.query));
                this.$router.push('/informationLanding');
            }catch(err){
                console.log('error in shippingAddressMedia mounted:',err);
                this.$router.push('/failed');
            }
        }else{
            //remove previous query info in localStorage: in case there is a previous record there
            localStorage.removeItem('shipping_page_query');
            this.$router.push('/failed');
        }
        this.loading = false;
    },
    data(){
        return{
            loading:false,
        }
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'storeAllQueryInfomation',
        ]),
        ...mapActions(useCommonStore, [
            'setAccessionId',
            'setSampleId',
        ])
    }
}
</script>

<style scoped>
.shipping-address-view{
    min-height: calc(100vh - 62px);
    padding-bottom: 114px;
}
@media only screen and (max-width: 767px){
    .shipping-address-view{
        padding-bottom: 104px;
    }
}
</style>