<template>
  <div>
    <iframe
      :src="`https://archive.vibrant-wellness.com/collection/${tubetype}`"
      class="w-[100vw] frame-height mt-[-98px]"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: ["tubetype"],
  created() {
    console.log(this.tubetype);
  },
};
</script>
<style scoped>
.frame-height {
  min-height: calc(100vh);
}

@media screen and (max-width: 780px) {
  .frame-height {
    min-height: calc(100vh + 38px);
  }
}
</style>
