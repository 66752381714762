<template>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you ever undergone a Radical Prostatectomy?<br>(Removal of Prostate Gland)"
      width="120px"
      :options="yesNoOptions"
      :value="this.hormoneZoomerQnr.basic_info.radical_prostatectomy"
      @onChange="handleValueChange('basic_info.radical_prostatectomy', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you had a bladder or prostate infection in the <br>last 12 months?"
      width="120px"
      :options="yesNoUnsureOptions"
      :value="this.hormoneZoomerQnr.basic_info.bladder_or_prostate_infection"
      @onChange="
        handleValueChange('basic_info.bladder_or_prostate_infection', $event)
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Bone Health and Toxin Exposure</div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you ever had a bone density scan?"
      width="120px"
      :options="yesNoOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure.bone_density_scan
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.bone_density_scan',
          $event
        )
      "
    />
  </div>
  <div
    class="expandedContainer"
    v-if="
      this.hormoneZoomerQnr.bone_health_and_toxic_exposure.bone_density_scan
    "
  >
    <QuestionnaireRadio
      content="If yes, what was the result?"
      width="165px"
      :options="boneDensityScanOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure.bone_density_result
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.bone_density_result',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you experienced any fractures?"
      width="120px"
      :options="yesNoOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure
          .experienced_fractures
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.experienced_fractures',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you ever been exposed to toxic substances?"
      width="120px"
      :options="yesNoUnsureOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure
          .toxic_substance_exposure
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.toxic_substance_exposure',
          $event
        )
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Symptom Assessment</div>
    <div class="assessmentHint">
      Please rate the severity of the following symptoms on a scale of 0-3:<b
        >0 = None</b
      ><b> 1 = Mild </b><b>2 = Moderate </b><b>3 = Severe</b>
    </div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Fatigue / Low Energy"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.fatigue"
      @onChange="handleValueChange('symptom_assessment.fatigue', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Decreased Sexual Desire (Libido)"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.loss_of_libido"
      @onChange="handleValueChange('symptom_assessment.loss_of_libido', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Erectile Dysfunction"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.erectile_dysfunction"
      @onChange="
        handleValueChange('symptom_assessment.erectile_dysfunction', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Decreased Muscle Mass"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.loss_of_muscle_mass"
      @onChange="
        handleValueChange('symptom_assessment.loss_of_muscle_mass', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Increased Body Fat / Weight Gain"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.increased_body_fat"
      @onChange="
        handleValueChange('symptom_assessment.increased_body_fat', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Depression/anxiety"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.depression_or_anxiety"
      @onChange="
        handleValueChange('symptom_assessment.depression_or_anxiety', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Poor Concentration or Memory Problems"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.difficulty_concentrating"
      @onChange="
        handleValueChange('symptom_assessment.difficulty_concentrating', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Poor sleep"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.poor_sleep"
      @onChange="handleValueChange('symptom_assessment.poor_sleep', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Reduced Physical Performance<br>(exercise capacity, strength)"
      width="auto"
      :options="severityOptions"
      :value="
        this.hormoneZoomerQnr.symptom_assessment.reduced_physical_performance
      "
      @onChange="
        handleValueChange(
          'symptom_assessment.reduced_physical_performance',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Irritability or Mood Swings"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.mood_swings"
      @onChange="handleValueChange('symptom_assessment.mood_swings', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Thinning or loss of hair"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.loss_of_hair"
      @onChange="handleValueChange('symptom_assessment.loss_of_hair', $event)"
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Medical History</div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you have a history of Hypogonadism<br>(low testosterone)? "
      width="120px"
      :options="yesNoUnsureOptions"
      :value="
        this.hormoneZoomerQnr.medical_history.personal_history.hypogonadism
      "
      @onChange="
        handleValueChange(
          'medical_history.personal_history.hypogonadism',
          $event
        )
      "
    />
  </div>
  <div class="subpartContainer justify-between items-center mb-[12px]">
    <QuestionnaireMultiChoice
      content="Do you have a history of:"
      width="auto"
      :options="maleDiseaseHistoryOptions"
      :value="this.comorbiditiesArray"
      @onChange="handleComorbidityChange"
    />
  </div>
  <div
    class="expandedContainer"
    v-if="this.comorbiditiesArray.includes('other')"
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.medical_history.comorbidities.other_comorbidities
      "
      class="expandInputField"
      @input="
        handleValueChange(
          'medical_history.comorbidities.other_comorbidities',
          $event
        )
      "
    />
  </div>
  <div class="subpartContainer justify-between items-center mb-[12px]">
    <QuestionnaireMultiChoice
      content="Do you have a familial history of:"
      width="auto"
      :options="maleFamilyHistoryOptions"
      :value="this.familyHistoryArray"
      @onChange="handleFamilyHistoryChange"
    />
  </div>
  <div
    class="expandedContainer"
    v-if="this.familyHistoryArray.includes('other')"
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.medical_history.family_history
          .other_family_conditions
      "
      class="expandInputField"
      @input="
        handleValueChange('family_history.other_family_conditions', $event)
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Treatment Considerations</div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Do you have a hormone treatment preference?"
      width="132px"
      :options="HRTOptions"
      :value="
        this.hormoneZoomerQnr.treatment_considerations
          .hormone_treatment_preference
      "
      @onChange="
        handleValueChange(
          'treatment_considerations.hormone_treatment_preference',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Do you have sensitive skin? (Affects certain forms of HRT)"
      width="132px"
      :options="yesNoOptions"
      :value="this.hormoneZoomerQnr.treatment_considerations.sensitive_skin"
      @onChange="
        handleValueChange('treatment_considerations.sensitive_skin', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Are you currently undergoing HRT and/or taking any medications? "
      width="132px"
      :options="yesNoOptions"
      :value="
        this.hormoneZoomerQnr.treatment_considerations
          .undergoing_hormone_therapy
      "
      @onChange="
        handleValueChange(
          'treatment_considerations.undergoing_hormone_therapy',
          $event
        )
      "
    />
  </div>
  <div
    class="expandedContainer"
    v-if="
      this.hormoneZoomerQnr.treatment_considerations.undergoing_hormone_therapy
    "
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.treatment_considerations.current_medications
      "
      class="expandInputField"
      @input="
        handleValueChange(
          'treatment_considerations.current_medications',
          $event
        )
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Addiontal Information</div>
  </div>
  <div class="subpartContainer justify-between items-center mb-[12px]">
    <el-input
      type="textarea"
      placeholder="Enter details here"
      class="inputTextarea"
      v-model="
        this.hormoneZoomerQnr.treatment_considerations.additional_information
      "
      @input="
        handleValueChange(
          'treatment_considerations.additional_information',
          $event
        )
      "
    />
  </div>
</template>

<script>
import QuestionnaireRadio from "./QuestionnaireRadio.vue";
import QuestionnaireMultiChoice from "./QuestionnaireMultiChoice.vue";
import { useHormoneZoomerStore } from "@/stores/HormoneZoomerStore.ts";
import { mapState, mapActions } from "pinia";
import {
  reproductiveHealthStageOptions,
  yesNoOptions,
  severityOptions,
  yesNoUnsureOptions,
  HRTOptions,
  maleDiseaseHistoryOptions,
  maleFamilyHistoryOptions,
  boneDensityScanOptions,
} from "@/utils/questionnaireOptions";

export default {
  name: "MaleRelatedQuestions",
  components: {
    QuestionnaireRadio,
    QuestionnaireMultiChoice,
  },
  data() {
    return {
      gender: "",
      lastMenstrualPeriod: "",
      reproductiveHealthStageOptions,
      yesNoOptions,
      severityOptions,
      yesNoUnsureOptions,
      HRTOptions,
      maleDiseaseHistoryOptions,
      maleFamilyHistoryOptions,
      boneDensityScanOptions,
    };
  },
  watch: {
    boneDensityScan: {
      deep: true,
      handler: function (newValue) {
        if (!newValue) {
          this.setHormoneZoomerQnrSingleField(
            "bone_health_and_toxic_exposure.bone_density_result",
            null
          );
        }
      },
    },
    undergoingHormoneTherapy: {
      deep: true,
      handler: function (newValue) {
        if (!newValue) {
          this.setHormoneZoomerQnrSingleField(
            "treatment_considerations.current_medications",
            ""
          );
        }
      },
    },
  },
  computed: {
    ...mapState(useHormoneZoomerStore, [
      "hormoneZoomerQnr",
      "comorbiditiesArray",
      "familyHistoryArray",
    ]),
    boneDensityScan() {
      return this.hormoneZoomerQnr.bone_health_and_toxic_exposure
        .bone_density_scan;
    },
    undergoingHormoneTherapy() {
      return this.hormoneZoomerQnr.treatment_considerations
        .undergoing_hormone_therapy;
    },
  },
  methods: {
    ...mapActions(useHormoneZoomerStore, [
      "getZoomerQnrData",
      "submitHormoneZoomerQnr",
      "setHormoneZoomerQnrSingleField",
      "setComorbiditiesArray",
      "setFamilyHistoryArray",
      "convertHormoneZoomerQnrArrayToObject",
    ]),
    handleValueChange(key, value) {
      this.setHormoneZoomerQnrSingleField(key, value);
    },
    handleComorbidityChange(value) {
      this.setComorbiditiesArray(value);
      this.convertHormoneZoomerQnrArrayToObject("comorbidities", value);
    },
    handleFamilyHistoryChange(value) {
      this.setFamilyHistoryArray(value);
      this.convertHormoneZoomerQnrArrayToObject("family_history", value);
    },
  },
};
</script>

<style scoped>
.subpartContainer {
  width: 100%;
  padding: 0 16px;
}

.inputField {
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #000000;
  border-radius: 4px;
}

.inputTextarea {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #000000;
}

:deep(.el-input__inner) {
  color: #000000;
}

:deep(.el-textarea__inner) {
  caret-color: #000 !important;
  color: #000000;
  white-space: normal;
  resize: none;
  height: 126px;
  box-shadow: none;
  border: 1px solid #bcccdc;
}

:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}

.subTitleContainer {
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #faece6;
  margin-top: 36px;
  margin-bottom: 24px;
}

.subTitle {
  color: #0e4672;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.17px;
}

.assessmentHint {
  font-family: Roboto;
  font-size: 14px;
  margin-top: 8px;
}

.assessmentHint b {
  padding-left: 15px;
  font-weight: 700;
  font-style: italic;
}

.expandedContainer {
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #f3f6f8;
  margin: 12px 16px;
}
</style>
