<template>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <div class="title">Last menstrual period</div>
    <el-input
      v-model="this.lastMenstrualPeriod"
      placeholder="MM/DD/YYYY"
      class="inputField"
      @blur="handleMenstrualDateChange"
    />
  </div>
  <div class="subpartContainer justify-between items-center mb-[12px]">
    <QuestionnaireRadio
      content="Which of the following best describes your current reproductive health status?"
      width="auto"
      :isRequired="true"
      requiredMessage="<br>* Required for populating reference ranges and appropriate report comments."
      :options="reproductiveHealthStageOptions"
      :value="this.hormoneZoomerQnr.basic_info.reproductive_health_stage"
      @onChange="
        handleValueChange('basic_info.reproductive_health_stage', $event)
      "
    />
  </div>
    <div
    class="expandedContainer"
    v-if="
      this.hormoneZoomerQnr.basic_info.reproductive_health_stage === 'PREMENOPAUSAL_PHASE'
    "
  >
    <QuestionnaireRadio
      content="If Premenopausal Phase, what was the result?"
      width="173px"
      :options="premenopausalPhaseResultOptions"
      :isRequired="true"
      :value="
        this.hormoneZoomerQnr.basic_info.current_menstrual_phase
      "
      @onChange="
        handleValueChange(
          'basic_info.current_menstrual_phase',
          $event
        )
      "
    />
  </div>
  <div
    class="expandedContainer"
    v-if="
      this.hormoneZoomerQnr.basic_info.reproductive_health_stage ===
      'PREMENOPAUSAL_PHASE'
    "
  >
    <QuestionnaireRadio
      content="If Premenopausal Phase, please indicate menstrual phase?"
      width="173px"
      :options="premenopausalPhaseResultOptions"
      :isRequired="true"
      requiredMessage=" * Required"
      :value="this.hormoneZoomerQnr.basic_info.current_menstrual_phase"
      @onChange="
        handleValueChange('basic_info.current_menstrual_phase', $event)
      "
    />
  </div>
  <div class="subpartContainer mb-[12px]" v-if="this.showWarning">
    <SimplifiedNotification
      message="This is a mandatory question."
      theme="red"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Do you have fairly regular periods?"
      width="120px"
      :options="yesNoOptions"
      :value="this.hormoneZoomerQnr.basic_info.regular_periods"
      @onChange="handleValueChange('basic_info.regular_periods', $event)"
    />
  </div>

  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you had a hysterectomy (surgical removal of the uterus)?"
      width="120px"
      :options="yesNoOptions"
      :value="this.hormoneZoomerQnr.basic_info.hysterectomy"
      @onChange="handleValueChange('basic_info.hysterectomy', $event)"
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Bone Health and Toxin Exposure</div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you ever had a bone density scan?"
      width="120px"
      :options="yesNoOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure.bone_density_scan
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.bone_density_scan',
          $event
        )
      "
    />
  </div>
  <div
    class="expandedContainer"
    v-if="
      this.hormoneZoomerQnr.bone_health_and_toxic_exposure.bone_density_scan
    "
  >
    <QuestionnaireRadio
      content="If yes, what was the result?"
      width="165px"
      :options="boneDensityScanOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure.bone_density_result
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.bone_density_result',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you experienced any fractures?"
      width="120px"
      :options="yesNoOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure
          .experienced_fractures
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.experienced_fractures',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Have you ever been exposed to toxic substances?"
      width="120px"
      :options="yesNoUnsureOptions"
      :value="
        this.hormoneZoomerQnr.bone_health_and_toxic_exposure
          .toxic_substance_exposure
      "
      @onChange="
        handleValueChange(
          'bone_health_and_toxic_exposure.toxic_substance_exposure',
          $event
        )
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Symptom Assessment</div>
    <div class="assessmentHint">
      Please rate the severity of the following symptoms on a scale of 0-3:<b
        >0 = None</b
      ><b> 1 = Mild </b><b>2 = Moderate </b><b>3 = Severe</b>
    </div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Hot flashes/night sweats"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.hot_flashes"
      @onChange="handleValueChange('symptom_assessment.hot_flashes', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Mood swings/irritability"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.mood_swings"
      @onChange="handleValueChange('symptom_assessment.mood_swings', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Fatigue"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.fatigue"
      @onChange="handleValueChange('symptom_assessment.fatigue', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Vaginal dryness"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.vaginal_dryness"
      @onChange="
        handleValueChange('symptom_assessment.vaginal_dryness', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Sleep disturbances"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.sleep_disturbances"
      @onChange="
        handleValueChange('symptom_assessment.sleep_disturbances', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Joint pain"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.joint_pain"
      @onChange="handleValueChange('symptom_assessment.joint_pain', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Loss of muscle mass"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.loss_of_muscle_mass"
      @onChange="
        handleValueChange('symptom_assessment.loss_of_muscle_mass', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Loss of libido"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.loss_of_libido"
      @onChange="handleValueChange('symptom_assessment.loss_of_libido', $event)"
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Urinary problems"
      width="auto"
      :options="severityOptions"
      :value="this.hormoneZoomerQnr.symptom_assessment.urinary_problems"
      @onChange="
        handleValueChange('symptom_assessment.urinary_problems', $event)
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Medical History</div>
  </div>
  <div class="subpartContainer justify-between items-center mb-[24px]">
    <QuestionnaireMultiChoice
      content="Do you have a history of:"
      width="auto"
      :options="femaleDiseaseHistoryOptions"
      :value="this.personalHistoryArray"
      @onChange="handlePersonHistoryChange"
    />
  </div>
  <div
    class="expandedContainer"
    v-if="this.personalHistoryArray.includes('other')"
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.medical_history.personal_history.other_cancer
      "
      class="expandInputField"
      @input="
        handleValueChange(
          'medical_history.personal_history.other_cancer',
          $event
        )
      "
    />
  </div>
  <div class="subpartContainer justify-between items-center mb-[24px]">
    <QuestionnaireMultiChoice
      content="Do you have any of the following comorbidities:"
      width="auto"
      :options="femaleComorbidityOptions"
      :value="this.comorbiditiesArray"
      @onChange="handleComorbidityChange"
    />
  </div>
  <div
    class="expandedContainer"
    v-if="this.comorbiditiesArray.includes('other')"
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.medical_history.comorbidities.other_comorbidities
      "
      class="expandInputField"
      @input="
        handleValueChange(
          'medical_history.comorbidities.other_comorbidities',
          $event
        )
      "
    />
  </div>
  <div class="subpartContainer justify-between items-center mb-[24px]">
    <QuestionnaireMultiChoice
      content="Do you have a familial history of:"
      width="auto"
      :options="femaleFamilyHistoryOptions"
      :value="this.familyHistoryArray"
      @onChange="handleFamilyHistoryChange"
    />
  </div>
  <div
    class="expandedContainer"
    v-if="this.familyHistoryArray.includes('other')"
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.medical_history.family_history
          .other_family_conditions
      "
      class="expandInputField"
      @input="
        handleValueChange('family_history.other_family_conditions', $event)
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Treatment Considerations</div>
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Do you have a hormone treatment preference?"
      width="132px"
      :options="HRTOptions"
      :value="
        this.hormoneZoomerQnr.treatment_considerations
          .hormone_treatment_preference
      "
      @onChange="
        handleValueChange(
          'treatment_considerations.hormone_treatment_preference',
          $event
        )
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Are you allergic to peanuts? (Affects certain forms of HRT)"
      width="132px"
      :options="yesNoOptions"
      :value="this.hormoneZoomerQnr.treatment_considerations.peanut_allergy"
      @onChange="
        handleValueChange('treatment_considerations.peanut_allergy', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Do you have sensitive skin? (Affects certain forms of HRT)"
      width="132px"
      :options="yesNoOptions"
      :value="this.hormoneZoomerQnr.treatment_considerations.sensitive_skin"
      @onChange="
        handleValueChange('treatment_considerations.sensitive_skin', $event)
      "
    />
  </div>
  <div
    class="subpartContainer flex flex-row justify-between items-center mb-[12px]"
  >
    <QuestionnaireRadio
      content="Are you currently undergoing HRT and/or taking any medications? "
      width="132px"
      :options="yesNoOptions"
      :value="
        this.hormoneZoomerQnr.treatment_considerations
          .undergoing_hormone_therapy
      "
      @onChange="
        handleValueChange(
          'treatment_considerations.undergoing_hormone_therapy',
          $event
        )
      "
    />
  </div>
  <div
    class="expandedContainer"
    v-if="
      this.hormoneZoomerQnr.treatment_considerations.undergoing_hormone_therapy
    "
  >
    <div class="title mb-[10px]">Please specify</div>
    <el-input
      v-model="
        this.hormoneZoomerQnr.treatment_considerations.current_medications
      "
      class="expandInputField"
      @input="
        handleValueChange(
          'treatment_considerations.current_medications',
          $event
        )
      "
    />
  </div>
  <div class="subTitleContainer">
    <div class="subTitle">Addiontal Information</div>
  </div>
  <div class="subpartContainer justify-between items-center mb-[12px]">
    <el-input
      type="textarea"
      placeholder="Enter details here"
      class="inputTextarea"
      v-model="
        this.hormoneZoomerQnr.treatment_considerations.additional_information
      "
      @input="
        handleValueChange(
          'treatment_considerations.additional_information',
          $event
        )
      "
    />
  </div>
</template>

<script>
import QuestionnaireRadio from "./QuestionnaireRadio.vue";
import QuestionnaireMultiChoice from "./QuestionnaireMultiChoice.vue";
import { useHormoneZoomerStore } from "@/stores/HormoneZoomerStore.ts";
import { mapState, mapActions } from "pinia";
import moment from "moment";
import SimplifiedNotification from "@/components/hormoneZoomer/SimplifiedNotification.vue";

import {
  reproductiveHealthStageOptions,
  yesNoOptions,
  severityOptions,
  yesNoUnsureOptions,
  HRTOptions,
  femaleDiseaseHistoryOptions,
  femaleComorbidityOptions,
  femaleFamilyHistoryOptions,
  boneDensityScanOptions,
  premenopausalPhaseResultOptions,
} from "@/utils/questionnaireOptions";

export default {
  name: "FemaleRelatedQuestions",
  components: {
    QuestionnaireRadio,
    QuestionnaireMultiChoice,
    SimplifiedNotification,
  },
  props: {
    showWarning: Boolean,
  },
  data() {
    return {
      lastMenstrualPeriod: "",
      reproductiveHealthStageOptions,
      yesNoOptions,
      severityOptions,
      yesNoUnsureOptions,
      HRTOptions,
      femaleDiseaseHistoryOptions,
      femaleComorbidityOptions,
      femaleFamilyHistoryOptions,
      boneDensityScanOptions,
      premenopausalPhaseResultOptions,
    };
  },
  watch: {
    showWarning: {
      immediate: true,
      handler(newVal) {
        console.log("showWarning", newVal);
      },
    },
    lastMenstrualPeriod: {
      handler(newVal) {
        let cleaned = newVal.replace(/\D/g, "");
        if (cleaned.length > 8) {
          cleaned = cleaned.slice(0, 8);
        }
        let formatted = "";
        if (cleaned.length > 0) {
          formatted = cleaned.slice(0, 2);
        }
        if (cleaned.length > 2) {
          formatted += "/" + cleaned.slice(2, 4);
        }
        if (cleaned.length > 4) {
          formatted += "/" + cleaned.slice(4, 8);
        }
        if (formatted !== newVal) {
          this.lastMenstrualPeriod = formatted;
        }
      },
    },
    boneDensityScan: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          this.setHormoneZoomerQnrSingleField(
            "bone_health_and_toxic_exposure.bone_density_result",
            null
          );
        }
      },
    },
    undergoingHormoneTherapy: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          this.setHormoneZoomerQnrSingleField(
            "treatment_considerations.current_medications",
            ""
          );
        }
      },
    },
    reproductiveHealthStage: {
      immediate: true,
      handler: function (newValue) {
        if (newValue !== "PREMENOPAUSAL_PHASE") {
          this.setHormoneZoomerQnrSingleField(
            "basic_info.current_menstrual_phase",
            null
          );
        }
      },
    },
    lastMenstrualDate: {
      immediate: true,
      handler(newVal) {
        this.lastMenstrualPeriod = newVal;
      },
    },
  },
  emits: ["removeWarning"],
  computed: {
    ...mapState(useHormoneZoomerStore, [
      "hormoneZoomerQnr",
      "personalHistoryArray",
      "comorbiditiesArray",
      "familyHistoryArray",
      "lastMenstrualDate",
    ]),
    boneDensityScan() {
      return this.hormoneZoomerQnr.bone_health_and_toxic_exposure
        .bone_density_scan;
    },
    undergoingHormoneTherapy() {
      return this.hormoneZoomerQnr.treatment_considerations
        .undergoing_hormone_therapy;
    },
    reproductiveHealthStage() {
      return this.hormoneZoomerQnr.basic_info.reproductive_health_stage;
    },
  },
  methods: {
    ...mapActions(useHormoneZoomerStore, [
      "getZoomerQnrData",
      "submitHormoneZoomerQnr",
      "setHormoneZoomerQnrSingleField",
      "setPersonalHistoryArray",
      "setComorbiditiesArray",
      "setFamilyHistoryArray",
      "setLastMenstrualDate",
      "convertHormoneZoomerQnrArrayToObject",
    ]),
    handleValueChange(key, value) {
      this.setHormoneZoomerQnrSingleField(key, value);
      if (key === "basic_info.reproductive_health_stage" || key === "basic_info.current_menstrual_phase") {
        this.$emit("removeWarning");
      }
    },
    handlePersonHistoryChange(value) {
      this.setPersonalHistoryArray(value);
      // convert array value to boolean
      this.convertHormoneZoomerQnrArrayToObject("personal_history", value);
    },
    handleComorbidityChange(value) {
      this.setComorbiditiesArray(value);
      // convert array value to boolean
      this.convertHormoneZoomerQnrArrayToObject("comorbidities", value);
    },
    handleFamilyHistoryChange(value) {
      this.setFamilyHistoryArray(value);
      // convert array value to boolean
      this.convertHormoneZoomerQnrArrayToObject("family_history", value);
    },
    handleMenstrualDateChange() {
      if (
        !moment(this.lastMenstrualPeriod).isValid() ||
        this.lastMenstrualPeriod.length !== 10
      ) {
        console.log("Invalid date");
        this.lastMenstrualPeriod = "";
        return;
      }
      this.setLastMenstrualDate(this.lastMenstrualPeriod);
      let date = moment(this.lastMenstrualPeriod, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      this.setHormoneZoomerQnrSingleField(
        "basic_info.last_menstrual_period",
        date
      );
    },
  },
};
</script>

<style scoped>
.subpartContainer {
  width: 100%;
  padding: 0px 16px;
}

.inputField {
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #000000;
  border-radius: 4px;
  width: 625px;
}

.expandInputField {
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #000000;
  border-radius: 4px;
  width: 760px;
}

.inputTextarea {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #000000;
}

:deep(.el-textarea__inner) {
  caret-color: #000 !important;
  white-space: normal;
  color: #000000;
  resize: none;
  height: 126px;
  box-shadow: none;
  border: 1px solid #bcccdc;
}

:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}

.subTitleContainer {
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #faece6;
  margin-top: 36px;
  margin-bottom: 24px;
}

.subTitle {
  color: #0e4672;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.17px;
}

.assessmentHint {
  font-family: Roboto;
  font-size: 14px;
  margin-top: 8px;
}

.assessmentHint b {
  padding-left: 15px;
  font-weight: 700;
  font-style: italic;
}

.expandedContainer {
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #f3f6f8;
  margin: 12px 12px;
}

:deep(.el-input__inner) {
  color: #000000;
}
@media only screen and (max-width: 808px) {
  .flex-row {
    flex-direction: column;
  }

  .inputField {
    width: 358px;
  }
}
</style>
