<template>
    <div class = "fedex-schedules-details-view-container" v-loading = "whole_view_loading" ref="fedex-schedules-details-view-container">
        <div v-if = "global_notifications && global_notifications.length > 0" class = "global-notifications-container" ref = "global-notifications-container">
            <SimplifiedNotification v-for = "(notification_props,idx) in global_notifications" 
            :key = "`${idx}-global-notification`" :ref = "`${idx}-global-notification`"
            v-bind = "notification_props"
            @close = "(element)=>handleCloseNotification(element,idx)"/>
        </div>

        <div class = "fedex-schedules-details-view" v-if = "current_pickup_num">
            <div class = "title-and-schedule-new-btn-container">
                <div class = "title">
                    Fedex Pickup System
                </div>
                <div class = "schedule-new-btn" @click="clickScheduleNewPickup()">
                    Schedule New Pickup
                </div>
            </div>
            <div class = "current-pickup-num-summary">
                Existing Scheduled Pickups ({{ current_pickup_num }})
            </div>
            <div class = "schedules-container" ref = "schedules-container">
                <div v-for = "(schedule, idx) in schedules" 
                :key = "`schedule-${schedule['schedule_id']}-container`" 
                class = "schedule-card-container">
                    <ScheduleCard :schedule_info = "schedule"
                    :index = "idx" />
                </div>
            </div>
        </div>

        <div class = "fedex-schedules-details-view" v-else>
            <div class = "title">
                Fedex Pickup System
            </div>
            <div class = "current-pickup-num-summary">
                Existing Scheduled Pickups ({{ current_pickup_num }})
            </div>
            <div class = "no-content-notice">
                There's no scheduled pickup related to this Accession ID yet.
            </div>
            <div class = "no-content-icon-wrapper">
                <img src = "@/assets/FedexPickup/no_content_icon.svg" class="no-content-icon">
            </div>
            <div class = "schedule-new-btn alway-fill-width" @click="clickScheduleNewPickup()">
                Schedule New Pickup
            </div>
        </div>
    </div>
    <SchedulePickupDialog v-if = "is_schedule_new_pickup_dialog_visible"
    title = "Schedule New Pickup"
    :default_schedule_info="new_schedule_info"
    @close = "handleScheduleNewPickupClose()"
    @confirm-edits="(edited_schedule_data)=>handleConfirmCreateNewSchedule(edited_schedule_data)"/>
</template>

<script>
import ScheduleCard from '@/components/fedexPickup/ScheduleCard.vue';
import SimplifiedNotification from '@/components/commons/SimplifiedNotification.vue';
import SchedulePickupDialog from "@/components/fedexPickup/SchedulePickupDialog.vue";

import { mapState, mapActions } from "pinia";
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
import { useCommonStore } from '@/stores/CommonStore.ts';
// import { createApp } from 'vue';
export default{
    components:{
        ScheduleCard,
        SchedulePickupDialog,
        SimplifiedNotification,
    },
    setup(){
        const fedexPickupStore = useFedexPickupStore();
        return{
            fedexPickupStore
        }
    },
    async mounted(){
        //if token in FedexPickStore are null or empty, means user refresh at the current page:
        // -> need to get the query from the localStorage, and reset variables in store
        this.whole_view_loading = true;
        if(!this.token){
            console.log('getLocalStorageQuery FedexPickupSearchPage /schedules',localStorage.getItem('fedex_pickup_query'));
            // 1. get query info from localStorage
            this.storeAllQueryInfomation(JSON.parse(localStorage.getItem('fedex_pickup_query')));
            //2. if role == internal: need to have both internal_token and token; else: only need to have token
            try{
                await this.checkAndGetTokenForInternalRole();
            }catch(err){
                //cancel whole_view_loading, as if no valid token(s), the following logic won't continue. The last canceling whole_view_loading won't be executed.
                this.whole_view_loading = false;
                //if the token check step throw an error: show error notification and stop all following logic 
                this.addNewGlobalNotification(
                    {
                        title: "Failed",
                        content: `${err.message}`,
                        build_in_theme: "red",
                        with_close_btn: true,
                    } 
                );
                console.log('get internal_token and token internal role err in FedexPickupSearchPage: ', err);
                return;
            }
            //3. after get neccessary tokens (pns token and internal token, if needed): get schedules data from backend
            try{
                //refetch schedules of current accession id when user refresh at the current page:
                console.log("refetch schedules of current accession id");
                await this.getCurrentAccessionIdFedexPickups(this.accession_id);
            }catch(err){
                //cancel loading if cannot get schedules and stop executing the following logic
                this.whole_view_loading = false;
                this.addNewGlobalNotification(
                    {
                        title: "Failed",
                        content: `Failed fetching all schedules under current accession id.`,
                        build_in_theme: "red",
                        with_close_btn: true,
                    } 
                );
                return;
            }
        }
        try{
            const backend_country_options = await this.getCountryOptions();
            this.setCountryOptionsForPhoneInput(backend_country_options);
        }catch(err){
            console.log('mounted getCountryOptions err', err);
            this.setCountryOptionsForPhoneInput(null);
        }
        //get prefilled address and contact info according to the number of the existing schedules:
        //if already have schedules under the current accession id:
        if(this.schedules && this.schedules.length > 0){
            //use most recent created schedule's pickup_address and contact info as the prefilled info
            //NOTES:schedules are sorted in DESCENDINGLY, so the first schedule is the most recent created one.
            const prefilled_info = {
                person_name: this.schedules[0].person_name,
                address: {
                    street: this.schedules[0].pickup_address.street,
                    city: this.schedules[0].pickup_address.city,
                    postal_code: this.schedules[0].pickup_address.postal_code,
                    state_or_province_code: this.schedules[0].pickup_address.state_or_province_code,
                    country_code: this.schedules[0].pickup_address.country_code,
                },
                contact:{
                    email:this.schedules[0].contact_email,
                    phone:{
                        area_code: this.schedules[0].contact_phone.area_code,
                        phone_number: this.schedules[0].contact_phone.phone_number,
                    }
                }
            }
            this.setPatientInfoOnFile(prefilled_info);
            this.initNewScheduleInfo();
            //need to get patient id anyway, no matter existing schedule amount
            //since patient_id is used to decide design version a&b, and set user name for fullstory
            if(!this.patient_id){
                try{
                    await this.getCustomerInfo();
                }catch(err){
                    this.addNewGlobalNotification(
                        {
                            title: "Failed",
                            content: `Failed fetching the patient information on file.`,
                            build_in_theme: "red",
                            with_close_btn: true,
                        } 
                    );
                }
            }
        }else{
        //if no schedules under the current accession id:
            //call to get patient info on profile as the prefilled info
            try{
                if(!this.patient_id){
                    await this.getCustomerInfo();
                }
                await this.getPatientInfoOnFile();
            }catch(err){
                this.whole_view_loading = false;
                this.addNewGlobalNotification(
                    {
                        title: "Failed",
                        content: `Failed fetching the patient information on file.`,
                        build_in_theme: "red",
                        with_close_btn: true,
                    } 
                );
            }
        }
        //if get patient_id successfully and not record_patient_in_fullstory
        if(this.patient_id && !this.record_patient_in_fullstory){
            // set user id (patient_id) for Sentry
            this.addPatientIDToSentry(this.patient_id);
            //add patient id to fullstory: record as a user
            this.addPatientIDToFullStory(this.patient_id);
        }
        //if no schedule, the schedule_new_pickup_dialog is default open:
        //NOTES: if error occurs when get schedules, the schedule will also be empty, in this situation, don't open schedule_new_pickup_dialog
        if(this.schedules.length==0 && this.global_notifications.length ==0){
            this.is_schedule_new_pickup_dialog_visible = true;
        }
        this.previous_length_cache_of_global_notifications = this.global_notifications.length;
        this.whole_view_loading = false;
    },
    data(){
        return{
            successNotification:{
                // title:'Success',
                build_in_theme:'green',
                content:`Your pickup request has been successfully completed, <span class = "notification-bold">Confirmation Number ${'#abc123'}</span>. An email confirmation will be sent to the email on file.`,
            },
            // notification_instances_collection:{},
            whole_view_loading:false,
            previous_length_cache_of_global_notifications:0,
            is_schedule_new_pickup_dialog_visible:false,
        }
    },
    computed:{
        ...mapState(useFedexPickupStore,[
            'token',
            'accession_id',
            'patient_id',
            'schedules',
            'new_schedule_info',
            'global_notifications',
            'record_patient_in_fullstory',
        ]),
        current_pickup_num(){
            return this.schedules? this.schedules.length:0;
        }
    },
    watch:{
        global_notifications:{
            deep:true,
            handler(newVal){
                const current_length = newVal.length;
                //if add new global notification: auto scroll page to show the new notification in the view port
                if(this.previous_length_cache_of_global_notifications < current_length){
                    this.$nextTick(() => {
                        const element = this.$refs[`${current_length-1}-global-notification`][0].$el;
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    });
                }
                //cache for later use
                this.previous_length_cache_of_global_notifications = current_length;
            }
        },
    },
    methods:{
        ...mapActions(useCommonStore,[
            'addPatientIDToSentry',
        ]),
        ...mapActions(useFedexPickupStore,[
            'storeAllQueryInfomation',
            'checkAndGetTokenForInternalRole',
            'setPatientInfoOnFile',
            'setCountryOptionsForPhoneInput',
            'getCountryOptions',
            'getCustomerInfo',
            'getPatientInfoOnFile',
            'getCurrentAccessionIdFedexPickups',
            'initNewScheduleInfo',
            'addNewScheduleInfoInSchedules',
            'removeGlobalNotification',
            'addNewGlobalNotification',
        ]),
        addPatientIDToFullStory(patientId){
            if (patientId) {
                let attempts = 0;
                const MAX_ATTEMPTS = 10;

                const intervalId = setInterval(() => {
                    try {
                        if (window.FS) {
                            window.FS('setIdentity', {
                                uid: patientId.toString(),
                                properties: {
                                    displayName: 'patient_' + patientId.toString()
                                }
                            });
                            this.fedexPickupStore.record_patient_in_fullstory = true;
                            clearInterval(intervalId);
                        }

                        if (++attempts >= MAX_ATTEMPTS) {
                            console.log('addPatientIDToFullStory: Max attempts reached.');
                            clearInterval(intervalId);  // Prevent infinite loop
                        }
                    } catch (error) {
                        console.log('addPatientIDToFullStory error:', error);
                        clearInterval(intervalId);
                    }
                }, 500);
            }
        },
        clickScheduleNewPickup(){
            console.log('clickScheduleNewPickup: create n');
            this.is_schedule_new_pickup_dialog_visible = true;
            // this.createNewNotification(this.successNotification);
        },
        // used for programatically mounting SimplifiedNotification
        // createNewNotification(notification_data){
        //     const timestamp = Date.now();
        //     const appInstance = createApp(SimplifiedNotification, 
        //         {
        //             ...notification_data,
        //             id:timestamp,
        //             notification_instances_collection: this.notification_instances_collection,
        //             mounted_programmatically:true
        //         }
        //     );
        //     this.notification_instances_collection[timestamp]=appInstance;
        //     const target_div = this.$refs['fedex-schedules-details-view-container'];
        //     const element = document.createElement('div');
        //     element.style.transition='all 0.5s ease-out';
        //     target_div.prepend(element);
        //     appInstance.mount(element);
        //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // },
        handleScheduleNewPickupClose(){
            //sync v-if condition of SchedulePickupDialog: is_schedule_new_pickup_dialog_visible
            this.is_schedule_new_pickup_dialog_visible = false;
            //reset new_schedule_info to the default value based on patient_info_on_file
            this.initNewScheduleInfo();
        },
        handleConfirmCreateNewSchedule(edited_schedule_data){
        //triggered when everything required for scheduling a pickup is filled and valid, 
        //and the user has already successfully saved this new schedule or saved the edits on this existing schedule.
        //edited_schedule_data is the info of the new schedule
            //1. front render: push the edited_schedule_data in schedules in the store
            this.addNewScheduleInfoInSchedules(edited_schedule_data);
            //2. show success notification:
            this.addNewGlobalNotification({
                title: "Success",
                content: `We have successfully scheduled the FedEx pickup at ${edited_schedule_data.pickup_info.pickup_date} ${edited_schedule_data.pickup_info.ready_time} as requested.`,
                build_in_theme: "green",
                with_close_btn: true,
            });
            //3. updated the prefilled info based on most recent created schedule's pickup_address and contact info (which is the one just created)
            const prefilled_info = {
                person_name: edited_schedule_data.person_name,
                address: {
                    street: edited_schedule_data.pickup_address.street,
                    city: edited_schedule_data.pickup_address.city,
                    postal_code: edited_schedule_data.pickup_address.postal_code,
                    state_or_province_code: edited_schedule_data.pickup_address.state_or_province_code,
                    country_code: edited_schedule_data.pickup_address.country_code,
                },
                contact:{
                    email:edited_schedule_data.contact_email,
                    phone:{
                        area_code: edited_schedule_data.contact_phone.area_code,
                        phone_number: edited_schedule_data.contact_phone.phone_number,
                    }
                }
            }
            this.setPatientInfoOnFile(prefilled_info);
            //4. close schedule new pickup dialog: set v-if condition is_schedule_new_pickup_dialog_visible to false;
            //NOTES: this won't trigger close event of dialog as it is outside v-if condition of the dialog, so we need to reset new_schedule_info manually.
            this.is_schedule_new_pickup_dialog_visible = false;
            //5. reset new_schedule_info to the default value based on patient_info_on_file
            this.initNewScheduleInfo();
            //6.auto scroll schedules-container to the top to show the new schedule
            this.$nextTick(()=>{
                if(this.$refs['schedules-container']){
                    this.$refs['schedules-container'].scrollTop = 0;
                }
            });
        },
        handleCloseNotification(element,idx){
            console.log('handleCloseNotification',element,idx);
            this.removeGlobalNotification(idx);
        }
    }
}
</script>

<style scoped>
.fedex-schedules-details-view-container{
    /* padding:28px 316px 0px 316px;
    height: calc(100vh - 88px); */
    /* 26px+28px=54px; 26px is the height of the switch accession id bar*/
    padding: 54px 316px 0px 316px;
    height: 100%;
    overflow:auto;
    display:flex;
    flex-direction:column;
    row-gap:12px;
}
.global-notifications-container{
    display:flex;
    flex-direction:column;
    row-gap:12px;
}
.fedex-schedules-details-view-container::-webkit-scrollbar{
    background:transparent;
    width:6px;
}
.fedex-schedules-details-view-container:hover::-webkit-scrollbar-track{
    /* same as .fedex-schedules-details-view-container's padding-top */
    margin-top: 54px;
    border-radius: 4px;
    /* background: #E5E5E5; */
    background:transparent;
}
.fedex-schedules-details-view-container::-webkit-scrollbar-thumb {
    background:transparent;
    border-radius: 4px;
}
.fedex-schedules-details-view-container:hover::-webkit-scrollbar-thumb{
    border-radius: 4px;
    background: #C4C4C4;
}
.fedex-schedules-details-view-container:hover::-webkit-scrollbar-thumb:hover{
    border-radius: 4px;
    background: #a8a8a8;
    cursor:pointer;
}
.fedex-schedules-details-view{
    display:flex;
    flex-direction:column;
    row-gap:24px;
}
.schedules-container{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    row-gap:20px;
    padding-right:4px;
    padding-bottom:100px;
    height:calc(100vh - 272px);
    overflow:auto;
}
.schedules-container::-webkit-scrollbar{
    background:transparent;
    width:6px;
}
.schedules-container:hover::-webkit-scrollbar-track{
    border-radius: 4px;
    /* background: #E5E5E5; */
    background:transparent;
}
.schedules-container::-webkit-scrollbar-thumb {
    background:transparent;
    border-radius: 4px;
}
.schedules-container:hover::-webkit-scrollbar-thumb{
    border-radius: 4px;
    background: #C4C4C4;
}
.schedules-container:hover::-webkit-scrollbar-thumb:hover{
    border-radius: 4px;
    background: #a8a8a8;
    cursor:pointer;
}
.schedule-card-container{
    width:calc(50% - 18px);
}
.title-and-schedule-new-btn-container{
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.title{
    color: #1E4670;
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
}
.schedule-new-btn{
    width:fit-content;
    height:fit-content;
    padding: 10px 24px;
    border-radius: 4px;
    background: #1E4670;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; 
    letter-spacing: 0.429px;
    text-align:center;
    cursor: pointer;
}
.schedule-new-btn:hover{
    background: #022A53;
}
.alway-fill-width{
    width:100%;
}
.current-pickup-num-summary{
    padding:12px 0px;
    color: #1E4670;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.175px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #BCCCDC;
}
.no-content-icon-wrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
}
.no-content-icon{
    width:514px;
    height:344px;
}
.no-content-notice{
    color: #1E4670;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.175px;
    text-align:center;
}
@media only screen and (max-width:1260px){
    .fedex-schedules-details-view-container{
        /* padding:28px 170px 0px 170px; */
        padding: 54px 170px 0px 170px;
    }
}
@media only screen and (max-width:1110px){
    .schedules-container{
        display:flex;
        flex-direction:column;
        align-items:center;
        row-gap:24px;
        flex-wrap:nowrap;
    }
    .schedule-card-container{
        width:100%;
    }
    :deep(.schedule-card .ops-btns-container){
        justify-content: flex-start;
    }
}
@media only screen and (max-width:767px){
    .fedex-schedules-details-view-container{
        margin-top: 0px;
        /* 40 + 28 + 4= 72px */
        /* height: calc(100vh - 72px);
        padding: 12px 28px 0px 28px; */
        height: 100%;
        /* 32px+12px = 44px; 32px is the height of the switch accession id bar in mobile version */
        padding: 44px 28px 0px 28px;
    }
    .fedex-schedules-details-view-container:hover::-webkit-scrollbar-track{
        /* same as .fedex-schedules-details-view-container's padding-top */
        margin-top: 44px;
    }
    .fedex-schedules-details-view{
        row-gap: 12px;
    } 
    .schedules-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 12px;
        padding-bottom: 20px;
        height: calc(100vh - 227px);
        overflow: auto;
    }
    .schedules-container::-webkit-scrollbar{
        background: transparent;
        width: 4px;
    }
    .title-and-schedule-new-btn-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 12px;
    }
    .title{
        color: #1E4670;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.51px;
    }
    .schedule-new-btn{
        width:100%;
        height:fit-content;
        padding: 10px 24px;
        border-radius: 4px;
        background: #1E4670;
        color: #FFF;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; 
        letter-spacing: 0.429px;
        cursor: pointer;
    }
    .current-pickup-num-summary{
        padding:8px 0px;
        color: #1E4670;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.175px;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #BCCCDC;
    }
    .no-content-icon{
        width:319px;
        height:213px;
    }
    .no-content-notice{
        color: #1E4670;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
}
</style>
