<template>
  <div :class="['notification-container', theme_class]">
    <div class="notification-icon">
      <img :src="notification_icon" alt="notification icon" />
    </div>
    <div :class="['notification-message', theme_class]" v-html="message"></div>
  </div>
</template>

<script>
import red_notification_icon from "@/assets/questionnaire/red_notification_icon.svg";

export default {
  name: "SimplifiedNotification",
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    notification_icon() {
      return this.theme === "red" ? red_notification_icon : "";
    },
    theme_class() {
      return this.theme;
    },
  },
};
</script>

<style scoped>
.notification-container.red {
  background: #faeaea;
}
.notification-container {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  border-radius: 8px;
  align-items: stretch;
  box-sizing: border-box;
  position: relative;
  align-items: center;
}

.notification-message {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.17px;
}

.notification-message.red {
  color: #df2b25;
}
</style>
