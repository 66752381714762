<template>
  <div class="subpartContainer payLaterContainer">
    <div class="paymentSubtitle">Pay Later Option</div>
    <PaymentRadio :options="adjusted_pay_later_options" @onChange="handlePayLaterChange" />
  </div>
  <div class="subpartContainer mb-1" v-if="payLaterOption != ''">
    <div class="paymentSubtitle">Billing Information</div>
    <BillingInfo :showAllBillingInfo="true" />
  </div>
  <div class="subpartContainer mb-1" v-if="payLaterOption != ''">
    <div class="paymentSubtitle">Contact Information</div>
    <ContactInfo />
  </div>
  <div class="subpartContainer" v-if="payLaterOption != ''">
    <el-button
      class="submitButton"
      @click="submitPayment"

    >
      Proceed to Pay
    </el-button>
  </div>
</template>

<script>
import PaymentRadio from "@/components/payment/PaymentRadio.vue";
import PayLaterOptions from "@/utils/payLaterOptions.js";
import BillingInfo from "@/components/payment/BillingInfo.vue";
import ContactInfo from "@/components/payment/ContactInfo.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapState, mapActions } from "pinia";
import { getPublishableKey } from "@/utils/stripeKeySelector";
// import { ElNotification } from "element-plus";
import { loadStripe } from "@stripe/stripe-js";
import  { isProd}  from "@/components/bloodDraw/BloodDrawData/request";
export default {
  name: "BnplContainer",
  components: {
    PaymentRadio,
    BillingInfo,
    ContactInfo,
  },
  data() {
    return {
      publishableKey: getPublishableKey(),
      payLaterOption: "",
      PayLaterOptions,
      returnURL: isProd ? "https://pns.vibrant-wellness.com/payment_status?bnpl=true":"https://staging.pns-singlepage.pages.dev/payment_status?bnpl=true",
      loadingPayment: false,
      patientId: localStorage.getItem("patient_id"),
      stripe: null,
    };
  },
  computed: {
    ...mapState(usePaymentStore, [
      'supported_bnpl_methods_data',
      "hasEmptyFields",
      "billing_country",
      "billing_state",
      "billing_zipCode",
      "billing_city",
      "billing_address",
      "billing_address2",
      "billing_email",
      "billing_firstName",
      "billing_lastName",
      'billingInfo',
      'is_real_update_concierge_service',
      'has_concierge_service',
    ]),
    adjusted_pay_later_options(){
      var output = [];
      if(this.supported_bnpl_methods_data && this.supported_bnpl_methods_data.klarna_eligible){
        output.push({ label: 'Klarna' , value: 'klarna' });
      }
      if(this.supported_bnpl_methods_data && this.supported_bnpl_methods_data.afterpay_clearpay_eligible){
        output.push({ label: 'After Pay', value: 'afterpay' });
      }
      return output;
    }
  },
  created() {
    this.setCheckEmptyFields(false);
  },
  async mounted() {
    if (this.billingInfo) {
      try {
        this.stripe = await loadStripe(this.publishableKey);
      } catch (err) {
        console.error("Failed to load Stripe:", err);
      }
    }
  },
  methods: {
    ...mapActions(useCommonStore,[
      'setShowVWLoadingAnimation',
    ]),
    ...mapActions(usePaymentStore, [
      "generateBnplIntent",
      "setCheckEmptyFields",
      "initHasEmptyFields",
      "saveBillingInfo",
      "updateConciergeServiceViaBackend",
    ]),
    handlePayLaterChange(value) {
      this.payLaterOption = value;
    },
    async submitPayment() {
      this.$matomo && this.$matomo.trackEvent("pns", "payment_page", "submitBnpl", 1);
      this.loadingPayment = true;
      // show whole page loading animation
      this.setShowVWLoadingAnimation(true);
      // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
      // let record_concierge_service_at_backend;
      // try{
      //   //update concierge service record at backend
      //   record_concierge_service_at_backend = await this.updateConciergeServiceViaBackend();
      // }catch(err){
      //   console.log(err);
      //   this.loadingPayment = false;
      //   // end whole page loading animation, when error
      //   this.setShowVWLoadingAnimation(false);
      //   ElNotification({
      //       title: 'Failed',
      //       type: 'error',
      //       customClass: "errorNotification",
      //       message: 'Add or Remove Concierge Service Failed',
      //       duration: 0,
      //   });
      //   this.$router.push("/payment_failed");
      //   return;
      // }
      // //after record the concierge service at the backend,
      // //if success record:
      // if(record_concierge_service_at_backend.success){
      // --------------------- remove update concierge service at backend operation before submiting payment. END-------------------------------------
      // if balance total is not 0, then processing payment submission
      if(this.billingInfo && Number(this.billingInfo.total)){
        let clientSecret;
        this.setCheckEmptyFields(true);
        this.initHasEmptyFields();
        // this.loadingPayment = true;
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (
          this.hasEmptyFields.length == 0 ||
          this.hasEmptyFields.includes(true)
        ) {
          console.log("should return", this.hasEmptyFields);
          this.loadingPayment = false;
          // end whole page loading animation
          this.setShowVWLoadingAnimation(false);
          return;
        }
        if (this.payLaterOption == "klarna") {
          try {
            clientSecret = await this.generateBnplIntent("klarna");
          } catch (error) {
            console.log(error);
            this.loadingPayment = false;
            // end whole page loading animation, when error
            this.setShowVWLoadingAnimation(false);
            this.$router.push("/payment_failed");
          }
        } else if (this.payLaterOption == "afterpay") {
          try {
            clientSecret = await this.generateBnplIntent("afterpay_clearpay");
          } catch (error) {
            console.log(error);
            this.loadingPayment = false;
            // end whole page loading animation, when error
            this.setShowVWLoadingAnimation(false);
            this.$router.push("/payment_failed");
          }
        }
        const paymentDetails = {
          payment_method: {
            billing_details: {
              email: this.billing_email,
              name: this.billing_firstName + " " + this.billing_lastName,
              address: {
                country: this.billing_country,
                line1: this.billing_address,
                line2: this.billing_address2,
                city: this.billing_city,
                state: this.billing_state,
                postal_code: this.billing_zipCode,
              },
            },
          },
          shipping: {
            name: this.billing_firstName + " " + this.billing_lastName,
            address: {
              country: this.billing_country,
              line1: this.billing_address,
              line2: this.billing_address2,
              city: this.billing_city,
              state: this.billing_state,
              postal_code: this.billing_zipCode,
            },
          },
          return_url: this.returnURL,
        };
        let paymentIntent, error;
        if (this.payLaterOption == "klarna") {
          ({ paymentIntent, error } = await this.handleConfirmKlarnaPayment(
            this.stripe,
            clientSecret,
            paymentDetails
          ));
        } else if (this.payLaterOption == "afterpay") {
          ({ paymentIntent, error } = await this.handleConfirmAfterpayPayment(
            this.stripe,
            clientSecret,
            paymentDetails
          ));
        }
        if (paymentIntent) {
          this.loadingPayment = false;
          // end whole page loading animation
          this.setShowVWLoadingAnimation(false);
          this.handlePopupRedirect(paymentIntent);
          // this.saveBillingInfo();
        }
        if (error) {
          this.loadingPayment = false;
          // end whole page loading animation, when error
          this.setShowVWLoadingAnimation(false);
          this.$router.push("/payment_failed");
        }
      }else{
      // if balance total is 0 or no billingInfo, fake pay and then go to payment success.
        // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
        // // if record_concierge_service_at_backend.real_operation: 
        // if(record_concierge_service_at_backend.real_operation){
        // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
        // if the concierge service is checked at last:
        if (this.has_concierge_service) {
          // 1. wait for 5s for all backend updates, close loading animation, and jump to /payment_succeed
          setTimeout(()=>{
            this.loadingPayment = false;
            this.setShowVWLoadingAnimation(false);
            this.$router.push("/payment_succeed");
          },5000);
        }else{
          // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
          // // if record_concierge_service_at_backend.real_operation == false: directly jump to /payment_succeed
          // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
          
          // if the recent operation of conceirge checkbox isn't a real operation calling the backend (this.is_real_update_concierge_service == false), 
          // or there isn't any operation on concierge checkbox (this.is_real_update_concierge_service == null): 
          //  -> directly jump to /payment_succeed
          this.$router.push("/payment_succeed"); 
        }
      }
      // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
      // }else{
      // // if record_concierge_service_at_backend.success != true
      // // NOTE: it won't happen currently as the above catch bracket will catch the error and end submitPayment()
      // // currently record_concierge_service_at_backend only has response with success field is true. Otherwise, throw an error.
      //   this.loadingPayment = false;
      //   // end whole page loading animation, when error
      //   this.setShowVWLoadingAnimation(false);
      //   this.$router.push("/payment_failed");
      // }
      // --------------------- remove update concierge service at backend operation before submiting payment. END-------------------------------------
    },
    async handleConfirmKlarnaPayment(stripe, clientSecret, paymentDetails) {
      try {
        const { paymentIntent, error } = await stripe.confirmKlarnaPayment(
          clientSecret,
          paymentDetails,
          { handleActions: false }
        );
        return { paymentIntent, error };
      } catch (error) {
        console.error("Error confirming Klarna payment:", error);
        return { error };
      }
    },
    async handleConfirmAfterpayPayment(stripe, clientSecret, paymentDetails) {
      try {
        const { paymentIntent, error } =
          await stripe.confirmAfterpayClearpayPayment(
            clientSecret,
            paymentDetails,
            { handleActions: false }
          );
        return { paymentIntent, error };
      } catch (error) {
        console.error("Error confirming Afterpay payment:", error);
        return { error };
      }
    },
    handlePopupRedirect(paymentIntent) {
      // const isMobileDevice =
      //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      //     navigator.userAgent
      //   );
      // if (isMobileDevice) {
      window.location.href = paymentIntent.next_action.redirect_to_url.url;
      // } else {
      //   const modal = document.createElement("div");
      //   modal.style.position = "fixed";
      //   modal.style.top = "0";
      //   modal.style.left = "0";
      //   modal.style.width = "100%";
      //   modal.style.height = "100%";
      //   modal.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      //   modal.style.zIndex = 9999;
      //   modal.style.display = "none";
      //   document.body.appendChild(modal);
      //   const width = 500;
      //   const height = 1000;
      //   const left = (window.innerWidth - width) / 2;
      //   const top = (window.innerHeight - height) / 2;
      //   const popup = window.open(
      //     "",
      //     "_blank",
      //     `width=${width},height=${height},left=${left},top=${top}`
      //   );
      //   modal.appendChild(popup.document.documentElement);
      //   modal.style.display = "block";
      //   popup.location.href = paymentIntent.next_action.redirect_to_url.url;
      //   let windowCheck = setInterval(() => {
      //     if (popup.closed) {
      //       clearInterval(windowCheck);
      //       modal.style.display = "none";
      //       document.body.removeChild(modal);
      //       this.$router.push({
      //         path: "/payment_status",
      //         query: {
      //           bnpl: true,
      //         },
      //       });
      //     }
      //   }, 1000);
    },
  },
};
</script>

<style scoped>
.payLaterContainer {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .payLaterContainer {
    margin-bottom: 12px;
  }
}
</style>
