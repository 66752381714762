<template>
    <div class = "fedex-pickup-search-page" v-loading = 'loading'>
        <div class = "title-container">
            <div class = "title-and-icon-wrapper">
                <div class = "title">
                    Fedex Pickup System
                </div>
                <img src = "@/assets/FedexPickup/fedex_icon.svg" class = "title-icon"/>
            </div>
            <div class = "description">
                Welcome to the Vibrant Self-Service FedEx Pickup System. Here, you can schedule new pickups and manage your existing ones. Please note that Accession IDs associated with completed or canceled orders are not valid for use.
            </div>
        </div>
        <div class = "search-input-container">
            <div class = "input-display-name required">
                Accession ID
            </div>
            <el-input :class = "[{'alert':show_alert}]" v-model = "fedexPickupStore.accession_id" placeholder = "10-digit accession ID"/>
            <div v-if = "show_alert" class = "alert-notes">
                {{ error_message_text }}
            </div>
        </div>
        <div :class = "[
                'continue-btn',
                {'disabled': !fedexPickupStore.accession_id}
            ]"
        @click = "handleClickContinue()">
            Continue
        </div>
    </div>
</template>

<script>
import { useCommonStore } from "@/stores/CommonStore.ts";
import { useFedexPickupStore } from '@/stores/FedexPickupStore.ts';
import { mapState, mapActions } from 'pinia';
import { ElNotification } from "element-plus";

export default{
    setup(){
        const fedexPickupStore = useFedexPickupStore();
        return{
            fedexPickupStore
        }
    },
    async mounted(){
        //loading first when visit search page
        this.loading = true;
        //1. if token in FedexPickStore are null or empty, means user refresh at the current page:
        // -> need to get the query from the localStorage, and reset variables in store
        if(!this.token){
            // console.log('getLocalStorageQuery FedexPickupSearchPage /search',localStorage.getItem('fedex_pickup_query'));
            this.storeAllQueryInfomation(JSON.parse(localStorage.getItem('fedex_pickup_query')));
        }
        //2. if role == internal: need to have both internal_token and token; else: only need to have token
        try{
            await this.checkAndGetTokenForInternalRole();
        }catch(err){
            //cancel loading as no valid token(s)
            this.loading = false;
            //if the token check step throw an error: show error notification and stop all following logic 
            ElNotification({
                title: 'Error',
                type: 'error',
                customClass: "errorNotification",
                message: err.message,
                duration: 0,
            });
            console.log('get internal_token and token internal role err in FedexPickupSearchPage: ', err);
            return;
        }
        //3. get patient_id in order to set user record in fullstory
        try{
            if(!this.patient_id){
                await this.getCustomerInfo();
            }
        }catch(err){
            console.log('getCustomerInfo err in FedexPickupSearchPage',err);
        }
        
        // if get patient_id successfully and not record_patient_in_fullstory
        if(this.patient_id && !this.record_patient_in_fullstory){
            // set user id (patient_id) for Sentry
            this.addPatientIDToSentry(this.patient_id);
            //add patient id to fullstory: record as a user
            this.addPatientIDToFullStory(this.patient_id);
        }

        //if the role is internal, and there is an accession_id in fedexPickupStore: directly trigger Continue
        if(this.role == 'internal' && this.accession_id){
            this.handleClickContinue();
        }
        //cancel loading anyway at the end of the mounted logic
        this.loading = false;
    },
    data(){
        return{
            show_alert:false,
            loading:false,
            error_message_text:'This Accession ID has been completed or canceled and cannot be used to schedule a FedEx pickup.',
        }
    },
    computed:{
        ...mapState(useFedexPickupStore,[
            'token',
            'internal_token',
            'role',
            'accession_id',
            'patient_id',
            'record_patient_in_fullstory',
        ]),
    },
    watch:{
        accession_id(){
            if(this.show_alert){
                this.show_alert=false;
            }
        }
    },
    methods:{
        ...mapActions(useCommonStore,[
            'setAccessionId',
            'addPatientIDToSentry',
        ]),
        ...mapActions(useFedexPickupStore,[
            'storeAllQueryInfomation',
            'checkAndGetTokenForInternalRole',
            'getCurrentAccessionIdFedexPickups',
            'getCustomerInfo',
        ]),
        addPatientIDToFullStory(patientId){
            if (patientId) {
                let attempts = 0;
                const MAX_ATTEMPTS = 10;

                const intervalId = setInterval(() => {
                    try {
                        if (window.FS) {
                            window.FS('setIdentity', {
                                uid: patientId.toString(),
                                properties: {
                                    displayName: 'patient_' + patientId.toString()
                                }
                            });
                            //update the record operation status in store.
                            this.fedexPickupStore.record_patient_in_fullstory = true;
                            clearInterval(intervalId);
                        }

                        if (++attempts >= MAX_ATTEMPTS) {
                            console.log('addPatientIDToFullStory: Max attempts reached.');
                            clearInterval(intervalId);  // Prevent infinite loop
                        }
                    } catch (error) {
                        console.log('addPatientIDToFullStory error:', error);
                        clearInterval(intervalId);
                    }
                }, 500);
            }
        },
        async handleClickContinue(){
            this.loading = true;
            try{
                await this.getCurrentAccessionIdFedexPickups(this.accession_id);
                this.setAccessionId(this.accession_id);
                //update the fedex-pickup-query with the current accession_id (might be different from the one passed by url as user can type in)
                // to allow user always see the current accession_id in '/fedexPickup/schedules'
                const updated_fedex_pickup_query = {
                    accession_id:this.accession_id,
                    token: this.token,
                    ...(this.internal_token && { internal_token: this.internal_token }),
                    ...(this.role && { role: this.role }),
                }
                localStorage.setItem('fedex_pickup_query', JSON.stringify(updated_fedex_pickup_query));
                this.loading = false;
                this.$router.push('/fedexPickup/schedules');
            }catch(err){
                this.show_alert = true;
                this.error_message_text = err?.response?.data?.message ?? 'This Accession ID has been completed or canceled and cannot be used to schedule a FedEx pickup.';
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.fedex-pickup-search-page{
    height:100%;
    /* 28px+26px = 54px */
    padding:54px 22% 0px 22%;
    display:flex;
    flex-direction:column;
    align-items:center;
    row-gap:36px;
}
.title-container{
    padding: 36px 38.5px;
    border-radius: 10px;
    background: #FAECE6;
    display:flex;
    flex-direction:column;
    row-gap: 16px;
}
.title-and-icon-wrapper{
    display:flex;
    justify-content:space-between;
}
.title{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.515px;
}
.title-icon{
    width:90px;
    height:30px;
}
.description{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.4px;
}
.search-input-container{
    width:100%;
    display:flex;
    flex-direction:column;
    row-gap:8px;
}
.input-display-name{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.515px;
}
.required::after{
    content:'*';
    color: #FF1D00;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.515px;
}
:deep(.el-input){
    --el-input-border-color:#BCCCDC;
    --el-input-hover-border-color:#DEE6ED;
    --el-input-focus-border-color:#9BB2CA;
    --el-input-border-radius:4px;
    --el-input-placeholder-color: #979797;
} 
:deep(.el-input.alert){
    --el-input-border-color:#DE583F;
    --el-input-hover-border-color:#DE583F;
    --el-input-focus-border-color:#DE583F;
    --el-input-border-radius:4px;
    --el-input-placeholder-color: #979797;
} 
:deep(.el-input__wrapper){
    padding:8px 12px;
}
:deep(.el-input__inner){
    --el-input-inner-height:20px;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.5px;
}
.alert-notes{
    color: #DE583F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.continue-btn{
    width:100%;
    padding: 10px 16px;
    border-radius: 4px;
    background: #0E4672;
    text-align:center;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.429px;
    cursor: pointer;
}
.continue-btn:hover{
    background: #022A53;
}
.continue-btn.disabled{
    background: #979797;
    cursor:not-allowed;
}
@media only screen and (max-width:767px){
    .fedex-pickup-search-page{
        /* 34+24 = 58px */
        /* height: calc(100vh - 58px); */
        height:100%;
        row-gap:24px;
        /* 8 + 24 = 32px; 8px due to header of this page is 8px more than the regular height, 24px is the actual distance between the content and the header*/
        padding:32px 7.5% 0px 7.5%;
    } 
    .title-container{
        padding: 20px 24px;
        border-radius: 10px;
        background: #FAECE6;
        display:flex;
        flex-direction:column;
        row-gap: 16px;
    }
    .title-and-icon-wrapper{
        display:flex;
        flex-direction:column-reverse;
        justify-content:flex-start;
        row-gap:12px;
    }
    .title{
        font-size: 20px;
        line-height: 23px;
    }
    .title-icon{
        width:75px;
        height:25px;
    }
    .description{
        font-size: 16px;
        line-height: 19px;
    }
    .search-input-container{
        width:100%;
        display:flex;
        flex-direction:column;
        row-gap:8px;
    }
    :deep(.el-input__inner){
        font-size: 14px;
        letter-spacing: 0.25px;
    }
}
</style>