<template>
  <div
    class="md:mt-16 md:mb-16 mt-8 mb-8 flex justify-center collection-control"
  >
    <div class="flex flex-col max-w-[1100px] w-full">
      <div class="text-[18px] leading-[22px] md:text-[28px] md:leading-[34px] font-bold text-[#1e4670] mb-8">
        Sample Type Instruction
      </div>
      <!-- <div class="mb-8 text-[14px] leading-[16px] md:text-[16px] md:leading-[20px]" v-if="lastName !== '' || firstName !== '' || instructions.length !== 0">
        Hi {{ lastName }}. {{firstName}}. <br class="mb-2">
        Below is a list of the sample kits included with your purchase. <br>
        Please ensure that you <span class="font-bold"> follow the instructions provided in each tutorial carefully when collecting your specimens.</span> This will help guarantee the accuracy of your results.<br>
        We appreciate your cooperation and look forward to assisting you further.
      </div> -->
      <div class="collection-display">
        <div
            class="collection-box flex flex-col overflow-hidden justify-between cursor-pointer"
            v-for="(test, index) in displayList"
            :key="index"
            @click="chooseCategoryV2(test.route)"
        >
          <div class="flex justify-between">
            <div class="flex flex-col pl-[20px] pt-[20px] justify-center w-3/5">
              <div
                  class="mb-3 font-bold text-[12px] leading-[15px] md:text-[22px] md:leading-[27px]"
              >
                {{ test.name }}
              </div>
              <div
                  class="font-medium text-[8px] leading-[10px] md:text-[14px] md:leading-[17px]"
              >
                Sample collection instruction
              </div>
            </div>
            <div class="w-2/5 h-full flex justify-end">
              <img :src="test.theSrcs" class="w-5/6"/>
            </div>
          </div>
          <div class="flex items-center">
            <div
                class="flex justify-end pt-[27px] pb-[27px] pl-[27px] w-full pr-[27px]"
            >
              <img src="@/assets/Collection/Arrow.svg" class="h-[10px] md:h-[16px]"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import microtubeImg from '@/assets/Collection/Navi/1.svg';
import urineTubeImg from '@/assets/Collection/Navi/2.svg';
import urine4TubesImg from '@/assets/Collection/Navi/3.svg';
import stoolImg from '@/assets/Collection/Navi/4.svg';
import dbsImg from '@/assets/Collection/Navi/5.svg';
import saliva4TubesImg from '@/assets/Collection/Navi/6.svg';
import saliva1TubeImg from '@/assets/Collection/Navi/7.webp';
import utiImg from '@/assets/Collection/Navi/8.svg'
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapActions } from "pinia";

export default {
  props: {
    instructions: {
      type: String,
      default: '',
    },
    accession_id: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.setAccessionId(this.accession_id);
    let instructionList = this.instructions.split("-");
    console.log(instructionList);
    if (instructionList.length !== 0) {
      this.displayList = this.displayList.filter(item => instructionList.includes(item.name));
    }
  },
  data() {
        return {
          displayList: [
            {name: 'Microtube (Blood)', route: 'microtube', theSrcs: microtubeImg},
            {name: 'Urine', route: 'urine-1tube', theSrcs: urineTubeImg},
            {name: 'Urine (4 tubes)', route: 'urine-4tubes', theSrcs:  urine4TubesImg},
            {name: 'Stool', route: 'stool', theSrcs: stoolImg},
            {name: 'DBS Card', route: 'dbs', theSrcs: dbsImg},
            {name: 'Saliva (4 tubes)', route: 'saliva-4tubes', theSrcs: saliva4TubesImg},
            {name: 'Saliva (1 tube)', route: 'saliva-1tube', theSrcs: saliva1TubeImg},
            {name: 'Urine (UTI)', route: 'uti', theSrcs: utiImg},
          ],
        };
    },
    methods:{
      ...mapActions(useCommonStore, ['setAccessionId']),
      chooseCategoryV2(route){
        this.$router.push('/collections/'+route);
      },
    },
};
</script>

<style scoped>
.collection-control {
  padding-left: 10%;
  padding-right: 10%;
}
.collection-display {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 27px;
  column-gap: 27px;
}
.collection-box {
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .collection-display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 14px;
    column-gap: 14px;
  }
}

@media only screen and (max-width: 468px) {
  .collection-display {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 14px;
    column-gap: 14px;
  }
}
</style>