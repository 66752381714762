<template>
  <el-radio
    border
    v-show="showApplePayBtn"
    label="applePay"
    id="applePayBtn"
    v-loading="loading"
  >
    <div class="flex items-center">
      <img
        class="appleImage"
        src="@/assets/payment/apple_pay.svg"
        alt="apple_pay"
      />
    </div>
  </el-radio>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { getPublishableKey } from "@/utils/stripeKeySelector";
// import { ElNotification } from "element-plus";
import { loadStripe } from "@stripe/stripe-js";
// import { getCountryCode } from "@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js";
export default {
  name: "ApplePayButton",
  data() {
    return {
      publishableKey: getPublishableKey(),
      showApplePayBtn: false,
      loading: false,
      // record_concierge_service_at_backend_cache: null,
      stripe: null,
      paymentRequest: null,
      waitForPaymentRequest: false,
    };
  },
  async mounted() {
    if (this.billingInfo) {
      try {
        this.stripe = await loadStripe(this.publishableKey);
        if (this.stripe) {
          this.stripeJS();
        } else {
          console.error("Failed to initialize Stripe.");
        }
      } catch (err) {
        console.error("Failed to load Stripe:", err);
      }
    }
  },
  watch: {
    billingInfo: {
      handler: function (val) {
        console.log("billing info changed", val);
        if (val) {
          this.waitForPaymentRequest = true;
        }
      },
      deep: true,
    },
    paymentRequest: {
      handler: function (val) {
        if (val && this.waitForPaymentRequest) {
          this.waitForPaymentRequest = false;
          this.updatePaymentRequest();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo",
      "is_international_payment",
      "sample_id",
      "accession_id",
      "charge_type_id",
      "patient_address",
      "customer_info",
      'is_real_update_concierge_service',
      'has_concierge_service',
    ]),
  },
  methods: {
    ...mapActions(useCommonStore, ["setShowVWLoadingAnimation"]),
    ...mapActions(usePaymentStore, [
      "generateStripeDigitalWalletPayIntent",
      // "updateConciergeServiceViaBackend",
    ]),
    stripeJS() {
      // this.showApplePayError = false;
      // need to first convert to be a valid country code, since there might be multiple format country value for both patient and provider info,
      // const patient_address_country_code =  this.patient_address.country ? getCountryCode(this.patient_address.country) : "";
      // const provider_address_country_code =  this.customer_info.countryName ? getCountryCode(this.customer_info.countryName) : "";
      // console.log("patient_address_country_code",patient_address_country_code);
      // console.log("provider_address_country_code",provider_address_country_code);
      this.paymentRequest = this.stripe.paymentRequest({
        //definitely get the updated and meaningful is_international_payment
        //as the created() of parent Component PaymentView definitely finished before mounted() of ApplePayButton is called.
        // country: this.is_international_payment
        // ? (
        //   patient_address_country_code && patient_address_country_code.length == 2
        //   ?
        //   patient_address_country_code
        //   : (
        //     provider_address_country_code && provider_address_country_code.length == 2
        //     ? provider_address_country_code
        //     :'US'
        //   )
        // )
        // :'US',
        country: "US", // use our stripe account country code
        currency: (this.billingInfo?.currency || "usd").toLowerCase(),
        total: {
          label:
            "SampleID: " +
            this.sample_id +
            ",AccessionID: " +
            this.accession_id +
            ",ChargeTypeID: " +
            this.charge_type_id,
          amount: Math.round(this.billingInfo.total * 100),
        },
        disableWallets: ["googlePay", "browserCard", "link"],
      });
      this.paymentRequest.canMakePayment().then((result) => {
        if (result) {
          if (result.applePay) {
            this.showApplePayBtn = true;
            return;
          }
        } else {
          console.log(result, "no result");
          console.log("error");
        }
      });
      document.getElementById("applePayBtn").addEventListener("click", async() => {
        this.$matomo &&
          this.$matomo.trackEvent(
            "pns",
            "payment_page",
            "payment_method:applePay",
            1
          );
        // --------------------- remove update concierge service at backend operation before submiting payment ---------------------------------
        // let record_concierge_service_at_backend;
        // try{
        //   //update concierge service record at backend
        //   record_concierge_service_at_backend = await this.updateConciergeServiceViaBackend();
        //   //cache the record_concierge_service reponse at the frontend for use when paymentRequest.on("paymentmethod")
        //   this.record_concierge_service_at_backend_cache = record_concierge_service_at_backend;
        // }catch(err){
        //   console.log(err);
        //   this.record_concierge_service_at_backend_cache = null;
        //   this.loading = false;
        //   ElNotification({
        //     title: 'Failed',
        //     type: 'error',
        //     customClass: "errorNotification",
        //     message: 'Add or Remove Concierge Service Failed',
        //     duration: 0,
        //   });
        //   this.$router.push("/payment_failed");
        // }
        // //after record the concierge service at the backend,
        // //update the amount value in paymentRequest instance
        // if (record_concierge_service_at_backend.success) {
        //   paymentRequest.update({
        //     total: {
        //       label:
        //         "SampleID: " +
        //         this.sample_id +
        //         ",AccessionID: " +
        //         this.accession_id +
        //         ",ChargeTypeID: " +
        //         this.charge_type_id, // Keep the current label
        //       amount: Math.round(this.billingInfo.total * 100), // Update with the new amount in cents
        //     },
        //   });
        // --------------------- remove update concierge service at backend operation before submiting payment.  END ---------------------------------

        // paymentRequest.update({
        //     total: {
        //       label:
        //         "SampleID: " +
        //         this.sample_id +
        //         ",AccessionID: " +
        //         this.accession_id +
        //         ",ChargeTypeID: " +
        //         this.charge_type_id, // Keep the current label
        //       amount: Math.round(this.billingInfo.total * 100), // Update with the new amount in cents, the new amount has already be updated in updateConciergeServiceViaBackend()
        //     },
        //   });
        
        // if balance total is not 0, then processing payment submission
        if (this.billingInfo && Number(this.billingInfo.total)) {
          this.paymentRequest.show();
        } else {
          // if balance total is 0 or no billingInfo, fake pay and then go to payment success.
          // --------------------- remove update concierge service at backend operation. -------------------------------------
          // // if record_concierge_service_at_backend.real_operation:
          // if(this.record_concierge_service_at_backend_cache.real_operation){
          // --------------------- remove update concierge service at backend operation.  END ---------------------------------
          // if the concierge service is checked at last:
          if (this.has_concierge_service) {
            // 1. show whole page loading animation
            this.setShowVWLoadingAnimation(true);
            // 2. wait for 5s for all backend updates, end loading animation, and jump to /payment_succeed
            setTimeout(() => {
              this.setShowVWLoadingAnimation(false);
              this.$router.push("/payment_succeed");
            }, 5000);
          } else {
            // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
            // // if record_concierge_service_at_backend.real_operation:
            // --------------------- remove update concierge service at backend operation before submiting payment.  END ---------------------------------
            // if the recent operation of conceirge checkbox isn't a real operation calling the backend (this.is_real_update_concierge_service == false), 
            // or there isn't any operation on concierge checkbox (this.is_real_update_concierge_service == null): 
            //  -> directly jump to /payment_succeed
            this.$router.push("/payment_succeed");
          }
        }

        // }
      });
      this.paymentRequest.on("paymentmethod", async (e) => {
        try {
          let clientSecret = await this.generateStripeDigitalWalletPayIntent();
          const { paymentIntent, error } = await this.stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            {
              handleActions: false,
            }
          );
          if (error) {
            e.complete("fail");
            this.$router.push("/payment_failed");
          }
          if (paymentIntent) {
            e.complete("success");
            // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
            // // if record_concierge_service_at_backend.real_operation:
            // if(this.record_concierge_service_at_backend_cache.real_operation){
            // --------------------- remove update concierge service at backend operation before submiting payment.  END ---------------------------------
            // if the concierge service is checked at last:
            if (this.has_concierge_service) {
              // 1. show whole page loading animation
              this.setShowVWLoadingAnimation(true);
              // 2. wait for 5s for all backend updates, close loading animation, and jump to /payment_succeed
              setTimeout(() => {
                this.setShowVWLoadingAnimation(false);
                this.$router.push("/payment_succeed");
              }, 5000);
            } else {
              // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
              // // if record_concierge_service_at_backend.real_operation == false: directly jump to /payment_succeed
              // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
              
              // if the recent operation of conceirge checkbox isn't a real operation calling the backend (this.is_real_update_concierge_service == false), 
              // or there isn't any operation on concierge checkbox (this.is_real_update_concierge_service == null): 
              //  -> directly jump to /payment_succeed
              this.$router.push("/payment_succeed");
            }
          }
        } catch (error) {
          console.log(error);
          this.$router.push("/payment_failed");
        }
      });
    },
    updatePaymentRequest() {
      this.paymentRequest.update({
        total: {
          label:
            "SampleID: " +
            this.sample_id +
            ",AccessionID: " +
            this.accession_id +
            ",ChargeTypeID: " +
            this.charge_type_id, // Keep the current label
          amount: Math.round(this.billingInfo.total * 100), // Update with the new amount in cents, the new amount has already be updated in updateConciergeServiceViaBackend()
        },
      });
    },
  },
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: space-between;
  flex-wrap: wrap;
}

:deep(.el-radio.is-bordered) {
  width: 27vw;
  /* min-width: 284px; */
  height: 80px;
  border-radius: 4px;
  border: 1px solid #bcccdc;
  margin-bottom: 15px;
  margin-right: 0px;
}

:deep(.el-radio__label) {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.46px;
  color: #000000;
}

:deep(.el-radio.is-bordered.is-checked) {
  border: 1px solid #20a8c3;
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  font-weight: 500;
  color: #20a8c3;
}

:deep(.el-radio__inner) {
  height: 20px !important;
  width: 20px !important;
  margin-left: 23px;
  margin-right: 23px;
  border: 2px #000000 solid !important;
}

:deep(.el-radio.is-disabled .el-radio__inner) {
  border: 2px #c4c4c4 solid !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border: 2px #20a8c3 solid !important;
  background: transparent !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner::after) {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #20a8c3;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px #20a8c3 solid !important;
}

.googleImage,
.appleImage,
.cardImage {
  margin-right: 8px;
}

.radioImage {
  margin-right: 16px;
}

@media only screen and (max-width: 1024px) {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    flex-wrap: nowrap;
  }

  .el-radio {
    margin-right: 0px;
    width: 100% !important;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .cardImage {
    margin-right: 4px;
    height: 12px;
  }

  .googleImage,
  .appleImage {
    margin-right: 8px;
    height: 20px;
  }

  .radioImage {
    margin-right: 8px;
    height: 20px;
  }

  .el-radio {
    height: 42px !important;
  }

  .el-radio div {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  :deep(.el-radio__inner) {
    height: 16px !important;
    width: 16px !important;
    margin-left: 12px;
    margin-right: 12px;
    border: 1px #000000 solid !important;
  }

  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border: 1px #20a8c3 solid !important;
  }
}
</style>
