<template>
  <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Card Information</div>
    <CardInfo @submitPayment="submitPayment" @triggerLoading="triggerLoading" />
  </div>
  <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Billing Information</div>
    <BillingInfo :showAllBillingInfo="false" />
  </div>
  <!-- <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Contact Information</div>
    <ContactInfo />
  </div> -->
  <div class="subpartContainer">
    <el-button
      class="submitButton"
      id="tokenizebutton"      
    >
      Complete Payment
      {{ formatPrice(amountToPay) }}</el-button
    >
  </div>
  <div class="consentMessageContainer">
    <div class="consentMessage">
      By clicking Complete Payment above, you agree for your card provided here
      to be charged the complete amount listed to purchase testing according to
      <a
        href="https://www.vibrant-america.com/website-terms-of-use/"
        target="_blank"
        >Terms of Service</a
      >
      and
      <a
        href="https://www.vibrant-america.com/testing-policies/"
        target="_blank"
        >Testing Policies</a
      >
      from Vibrant America and
      <a href="https://www.vibrant-wellness.com/TermsOfUse" target="_blank"
        >Terms of Service</a
      >
      from Vibrant Wellness.
    </div>
  </div>
</template>

<script>
import BillingInfo from "@/components/payment/BillingInfo.vue";
import CardInfo from "@/components/payment/CardInfo.vue";
// import ContactInfo from "@/components/payment/ContactInfo.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapState, mapActions } from "pinia";
// import { ElNotification } from "element-plus";

export default {
  name: "CardPayContainer",
  components: {
    BillingInfo,
    CardInfo,
    // ContactInfo,
  },
  data() {
    return {
      paymentMethod: "",
      loadingPayment: false,
      patientId: localStorage.getItem("patient_id"),
      // amountToPay: null,
    };
  },
  created() {
    this.setCheckEmptyFields(false);
    // this.setAmountToPay();
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo",
      "has_concierge_service",
      "backend_has_concierge_service",
      'is_real_update_concierge_service',
      'has_concierge_service'
    ]),
    formatPrice() {
      return (price) => {
        return Number(price).toLocaleString("en-US", {
          style: "currency",
          currency: (this.billingInfo?.currency || 'USD').toUpperCase(),
        }).replace('CHF','₣');
      };
    },
    amountToPay(){
      return localStorage.getItem("amount_to_pay")
        ? parseFloat(
            (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
          )
        : 
        (
          this.billingInfo
          ? 
          (
            this.need_to_manually_add_concierge_service_fee
            ?
            Number(this.billingInfo.total) + 99
            :
            (
              this.need_to_manually_deduct_concierge_service_fee
              ?
              Number(this.billingInfo.total) - 99
              :
              Number(this.billingInfo.total)
            )
          )
          : null
        );
    },
    need_to_manually_add_concierge_service_fee(){
      // console.log('need_to_manually_add_concierge_service_fee', this.has_concierge_service,this.backend_has_concierge_service);
      return this.has_concierge_service && !this.backend_has_concierge_service;
    },
    need_to_manually_deduct_concierge_service_fee(){
      // console.log('need_to_manually_deduct_concierge_service_fee', this.has_concierge_service,this.backend_has_concierge_service);
      return !this.has_concierge_service && this.backend_has_concierge_service;
    }
  },
  methods: {
    ...mapActions(useCommonStore,[
      'setShowVWLoadingAnimation'
    ]),
    ...mapActions(usePaymentStore, [
      "payByCard",
      "setCheckEmptyFields",
      "saveBillingInfo",
      "updateConciergeServiceViaBackend",
    ]),
    // setAmountToPay() {
    //   this.amountToPay = localStorage.getItem("amount_to_pay")
    //     ? parseFloat(
    //         (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
    //       )
    //     : this.billingInfo
    //     ? Number(this.billingInfo.total)
    //     : null;
    // },
    triggerLoading(val) {
      this.loadingPayment = val;
    },
    async submitPayment(token) {
      this.$matomo &&
        this.$matomo.trackEvent(
          "pns",
          "payment_page",
          "submitPayByCard",
          1
        );
      // show whole page loading animation
      this.setShowVWLoadingAnimation(true);
      // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
      // let record_concierge_service_at_backend;
      // try{
      //   //update concierge service record at backend
      //   record_concierge_service_at_backend = await this.updateConciergeServiceViaBackend();
      // }catch(err){
      //   console.log(err);
      //   this.triggerLoading(false);
      //   // end whole page loading animation, when error
      //   this.setShowVWLoadingAnimation(false);
      //   ElNotification({
      //       title: 'Failed',
      //       type: 'error',
      //       customClass: "errorNotification",
      //       message: 'Add or Remove Concierge Service Failed',
      //       duration: 0,
      //   });
      //   this.$router.push("/payment_failed");
      // }
      // //after record the concierge service at the backend,
      // //then processing payment submission
      // if(record_concierge_service_at_backend.success){
      // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
      // if balance total is not 0, then processing payment submission
      if(this.billingInfo && Number(this.billingInfo.total)){
        this.payByCard(token)
        .then(() => {
          // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
          // // if record_concierge_service_at_backend.real_operation: 
          // // NOTE: means truely send the request to update the backend record
          // if(record_concierge_service_at_backend.real_operation){
          // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
          // if the concierge service is checked at last:
          if (this.has_concierge_service) {
            // wait for 5s for all backend updates, end loading animation, and jump to /payment_succeed
            setTimeout(()=>{
              this.triggerLoading(false);
              // end whole page loading animation
              this.setShowVWLoadingAnimation(false);
              this.$router.push("/payment_succeed");
            },5000);
          }else{
          // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
          // // if record_concierge_service_at_backend.real_operation == false: directly jump to /payment_succeed
          // --------------------- remove update concierge service at backend operation before submiting payment.  END-------------------------------------

          // if the recent operation of conceirge checkbox isn't a real operation calling the backend (this.is_real_update_concierge_service == false), 
          // or there isn't any operation on concierge checkbox (this.is_real_update_concierge_service == null): 
          //  -> directly jump to /payment_succeed
            this.triggerLoading(false);
            // end whole page loading animation
            this.setShowVWLoadingAnimation(false);
            this.$router.push("/payment_succeed");
          }
        })
        .catch((err) => {
          console.log(err);
          this.triggerLoading(false);
          // end whole page loading animation, when error
          this.setShowVWLoadingAnimation(false);
          this.$router.push("/payment_failed");
        });
      }else{
      // if balance total is 0 or no billingInfo, fake pay and then go to payment success.
          // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
          // // if record_concierge_service_at_backend.real_operation: 
          // // NOTE: means truely send the request to update the backend record
          // if(record_concierge_service_at_backend.real_operation){
          // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
          // if the concierge service is checked at last:
          if (this.has_concierge_service) {
            // wait for 5s for all backend updates, end loading animation, and jump to /payment_succeed
            setTimeout(()=>{
              this.triggerLoading(false);
              // end whole page loading animation
              this.setShowVWLoadingAnimation(false);
              this.$router.push("/payment_succeed");
            },5000);
          }else{
          // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
          // // if record_concierge_service_at_backend.real_operation == false: directly jump to /payment_succeed
          // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
            
          // if the recent operation of conceirge checkbox isn't a real operation calling the backend (this.is_real_update_concierge_service == false), 
          // or there isn't any operation on concierge checkbox (this.is_real_update_concierge_service == null): 
          //  -> directly jump to /payment_succeed
          this.triggerLoading(false);
          // end whole page loading animation
          this.setShowVWLoadingAnimation(false);
          this.$router.push("/payment_succeed");
        }
      }
      // --------------------- remove update concierge service at backend operation before submiting payment. -------------------------------------
      // }
      // --------------------- remove update concierge service at backend operation before submiting payment. END -------------------------------------
    },
  },
};
</script>

<style scoped>
.consentMessageContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

a {
  color: #20a8c3;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.consentMessage {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.45px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .consentMessage {
    font-size: 12px;
  }
}
</style>
