<template>
  <div class="radioTitle">
      <span v-html="content"></span>
      <span v-if="isRequired" class="required" v-html="requiredMessage"></span>
  </div>

  <el-radio-group v-model="selectedOption" @change="onChange" class='radioGroup'>
    <el-radio
      v-for="option in options"
      :key="option.value"
      :label="option.value"
      border
    >
      {{ option.label }}
    </el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: "QuestionnaireRadio",
  props: {
    isRequired: {
      type: Boolean,
    },
    content: {
      type: String,
      required: true,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: "",
    },
    requiredMessage: {
      type: String,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedOption = newValue;
      },
    },
  },
  emits: ["onChange"],
  data() {
    return {
      selectedOption: this.value,
      radioHeight: this.height === "tall" ? "80px" : "42px",
      radioWidth: this.width === "wide" ? "27vw" : this.width,
    };
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.selectedOption);
    },
  },
};
</script>

<style scoped>

.radioTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  margin-bottom: 8px;
  white-space: pre-line;
}

.required {
  color: #ff1d00;
  line-height: 20px;
}

.el-radio-group {
  display: flex;
  /* gap:15px; */
  padding: 0px;
  box-sizing: border-box;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 18px;
}

:deep(.el-radio.is-bordered) {
  width: v-bind("radioWidth");
  /* min-width: 284px; */
  min-height: v-bind("radioHeight");
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #bcccdc;
  margin-right: 0px;
  background-color: #ffffff;
}

:deep(.el-radio__label) {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.46px;
  color: #000000;
  white-space: normal;
}

:deep(.el-radio.is-bordered.is-checked) {
  border: 1px solid #20a8c3;
  background-color: rgba(32, 168, 195, 0.1);
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: #20a8c3;
}

:deep(.el-radio__inner) {
  height: 20px !important;
  width: 20px !important;
  border: 2px #000000 solid;
  margin-left: 5px;
  margin-right: 5px;
}

:deep(.el-radio__input.is-disabled .el-radio__inner) {
  border: 2px #bcccdc solid;
  background: transparent !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border: 2px #20a8c3 solid !important;
  background: transparent !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner::after) {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #20a8c3;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px #20a8c3 solid !important;
}


@media only screen and (max-width: 808px) {
  .el-radio-group {
    flex-direction: column;
  }
  :deep(.el-radio.is-bordered) {
    min-height: 42px;
    height: fit-content;
  }

  .radioTitle {
    font-size: 14px;
  }

  :deep(.el-radio__label) {
    font-size: 12px;
  }

  .el-radio div {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  :deep(.el-radio__inner) {
    height: 16px !important;
    width: 16px !important;
    margin-left: 12px;
    margin-right: 12px;
    border: 1px #000000 solid !important;
  }

  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border: 1px #20a8c3 solid !important;
  }
}
</style>
