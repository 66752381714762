<template>
  <div id="page-container">
    <div class="pageBodyContainer">
      <img
        class="paymentStatusIcon"
        src="@/assets/payment/pending.svg"
        alt="payment pending"
      />
      <div class="paymentStatusTitle">Order Pending!</div>
      <div class="paymentStatusInfo">
        Your healthcare provider is coordinating necessary steps with us to ensure your testing proceeds smoothly. Please come back later.
      </div>
      <div class="paymentDetailsLink">
        If you need to view your payment details
        <a
          href="https://www.vibrant-america.com/patient-nav-portal"
          target="_blank"
          >&nbsp;Log in to PNS Portal</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProviderPaidWarning",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  position: relative;
}

.paymentStatusInfo {
  margin-bottom: 46px !important;
}

.paymentDetailsLink {
  margin-top: 0px !important;
}

@media only screen and (max-width: 767px) {
  .paymentStatusInfo {
    margin-bottom: 36px !important;
  }
}
</style>
