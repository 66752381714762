<template>
  <div id="page-container" v-loading="this.loadingQuestionnaire">
    <div class="pageBodyContainer">
      <QuestionnaireHeader />
      <div class="questionnaireContent">
        <div class="questionnaireDivider mt-[18px] mb-[16px]" />
        <div class="subpartContainer flex flex-row justify-between items-center mb-[18px]">
          <div class="title">Age</div>
          <el-input
            v-model="this.age"
            class="inputField"
            @input="handleAgeChange"
          />
        </div>
        <div class="subpartContainer flex flex-row justify-between items-center mb-[20px]">
          <QuestionnaireRadio
            content="Gender"
            width="337.5px"
            :options="genderOptions"
            :value="this.gender"
            @onChange="handleGenderChange"
          />
        </div>
        <div v-if="this.gender == 'Female'">
          <FemaleRelatedQuestions :showWarning="this.showWarning" @removeWarning="removeWarning"/>
        </div>
        <div v-if="this.gender == 'Male'">
          <MaleRelatedQuestions />
        </div>
        <div
          class="confirmContainer"
          v-loading="loadingSubmit"
        >
          <el-button class="saveButton" @click="this.saveQuestionnaire">
            Save for Now</el-button
          >
          <el-button
            class="submitQuestionnaireButton"
            @click="checkAllFieldsFilled"
            >Submit</el-button
          >
        </div>
      </div>
      <img id="footerImage" alt="Footer Image" src="@/assets/footerImage.svg" />
    </div>
  </div>
  <el-dialog
    v-model="showSubmitDialog"
    center
    modal-class="hormoneDialog confirmDialog"
  >
    <div class="dialogTitle">Are you sure to submit your questionnaire?</div>
    <div class="dialogContent">
      Results can not be modified after your submission.
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="cancelBtn" @click="this.showSubmitDialog = false"
          >Cancel</el-button
        >
        <el-button class="submitBtn" @click="this.confirmSubmitQuestionnaire">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="showSaveDialog"
    center
    modal-class="hormoneDialog confirmDialog"
  >
    <div class="dialogTitle">
      We have saved your results but haven't submitted.
    </div>
    <div class="dialogContent">
      You can come back here again via email link or PNS to continue filling in.
      Please click on <span class="font-medium">"Submit"</span> button once you
      finished this questionnaire.
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="submitBtn" @click="this.showSaveDialog = false">
          Done
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import QuestionnaireHeader from "./QuestionnaireHeader.vue";
import QuestionnaireRadio from "./QuestionnaireRadio.vue";
import FemaleRelatedQuestions from "./FemaleRelatedQuestions.vue";
import MaleRelatedQuestions from "./MaleRelatedQuestions.vue";
import { useHormoneZoomerStore } from "@/stores/HormoneZoomerStore.ts";
import { mapState, mapActions } from "pinia";
import { genderOptions} from "@/utils/questionnaireOptions.js";


export default {
  name: "QuestionnaireView",
  components: {
    QuestionnaireHeader,
    QuestionnaireRadio,
    FemaleRelatedQuestions,
    MaleRelatedQuestions,
  },
  data() {
    return {
      genderOptions,
      showSaveDialog: false,
      showSubmitDialog: false,
      allFieldsFilled: false,
      showWarning: false,
    };
  },
  async created() {
    try {
      await this.getZoomerQnrData();
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    ...mapState(useHormoneZoomerStore, [
      "gender",
      "age",
      "hormoneZoomerQnr",
      "loadingQuestionnaire",
      "loadingSubmit",
    ]),
  },
  watch: {
    hormoneZoomerQnr: {
      deep: true,
      handler: function (newValue) {
        if (newValue) {
          this.updateAllFieldsFilled();
        }
      },
    },
    gender: {
      immediate: true,
      handler() {
        this.updateAllFieldsFilled();
      },
    },
  },
  methods: {
    ...mapActions(useHormoneZoomerStore, [
      "getZoomerQnrData",
      "submitHormoneZoomerQnr",
      'setAge',
      'setGender',
    ]),
    handleGenderChange(value) {
      this.setGender(value);
    },
    handleAgeChange(value) {
      this.setAge(value);
    },
    saveQuestionnaire() {
      // pass true means only save for now
      this.submitHormoneZoomerQnr(true);
      this.showSaveDialog = true;
    },
    confirmSubmitQuestionnaire() {
      // pass false means actual submit
      this.submitHormoneZoomerQnr(false);
      this.showSubmitDialog = false;
    },
    checkAllFieldsFilled() {
      if (this.allFieldsFilled) {
        this.showSubmitDialog = true;
      } else {
        this.showWarning = true;
        window.scrollTo(0, 0);
      }
    },
    removeWarning() {
      this.showWarning = false;
    },
    updateAllFieldsFilled() {
      let allFieldsNeeded = {}
      if (this.gender == "Female") {
        allFieldsNeeded = {
          current_reproductive_health_stage: this.hormoneZoomerQnr.basic_info.reproductive_health_stage,
        };
        if (this.hormoneZoomerQnr.basic_info.reproductive_health_stage === 'PREMENOPAUSAL_PHASE') {
          allFieldsNeeded = {
            ...allFieldsNeeded,
            current_menstrual_phase :this.hormoneZoomerQnr.basic_info.current_menstrual_phase
          };
        }
      } 
      if(allFieldsNeeded) {
        this.allFieldsFilled = Object.values(allFieldsNeeded).every(
          (field) => (field !== null && field !== undefined)
        );
      } else {
        this.allFieldsFilled = true;
      }
      // this.allFieldsFilled = Object.values(allFields).every(
      //   (field) => (field !== null && field !== undefined)
      // );
    },
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  min-height: 1000px;
  position: relative;
}

.questionnaireContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 840px;
}

.subpartContainer {
  width: 100%;
  padding: 0 16px;
}

.confirmContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 300px;
}

.saveButton {
  border: 1px solid #0e4672;
  background: #ffffff;
  margin-right: 10px;
  width: 395px;
  height: 42px;
  color: #0e4672;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.4px;
}

.submitQuestionnaireButton {
  border: 1px solid #0e4672;
  background: #0e4672;
  width: 395px;
  height: 42px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.4px;
}

.saveButton:hover {
  border: 1px solid #5194d2;
  color: #5194d2;
}

.submitQuestionnaireButton:hover {
  border: 1px solid #5194d2;
  background: #5194d2;
}

.submitQuestionnaireButton.is-disabled {
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
  color: #bbbbbb !important;
}

.dialogTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.46px;
  text-align: center;
  color: #0e4672;
  margin-bottom: 10px;
}

.dialogContent {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.46px;
  text-align: center;
  color: #0e4672;
}

.inputField {
  width: 692px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #000000;
  border-radius: 4px;
}

:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid #bcccdc;
  padding-left: 21px;
}


@media only screen and (max-width: 808px) {
  #footerImage {
    display: none;
  }

  .questionnaireContent {
    width: 358px;
  }

  .confirmContainer {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 50px;
  }

  .saveButton {
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .submitQuestionnaireButton {
    width: 100%;
    margin-left: 0px !important;
    font-size: 12px;
  }
}
</style>
