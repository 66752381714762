import { defineStore } from "pinia";
import { fedexPickupBaseURL, fedexPickupOrderBaseURL, sampleBaseURL } from '@/components/fedexPickup/FedexPickupData/request.js';
import { getCountryCode } from "@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js";
import { country_code_to_area_code_map } from "@/components/bloodDraw/BloodDrawData/AreaCodeMap.js";
import { test_schedules } from "@/components/fedexPickup/FedexPickupData/testData.js";
import moment from "moment";

export const useFedexPickupStore = defineStore('FedexPickupStore',{
    state:()=>{
        return{
            record_patient_in_fullstory:false,
            accession_id:null,
            token:null,
            internal_token:null,
            role:null,
            patient_id:null,
            global_notifications:[],

            schedules:[],
            // this is the patient info on file, used as deafult value
            patient_info_on_file: {},
            // this is the patient info shown on schedule pickup
            new_schedule_info:{},
            dialog_view_history_stack:[],
            // pickup time section variables
            pickup_time_section_required_input_fields:[],
            // edit pickup address section variables
            edit_pickup_address_required_input_fields:[],
            edit_pickup_address_invalid_value_input_fields:[],
            // edit contact section variables
            country_options_for_phone_input:null,
            edit_contact_required_input_fields:[],
            edit_contact_invalid_value_input_fields:[],
        }
    },
    actions:{
        storeAllQueryInfomation(query){
            if(query){
                this.accession_id = query.accession_id;
                this.token = query.token;
                // internal_token is REQUIRED if role == 'internal'
                this.internal_token = query.internal_token;
                this.role = query.role;
            }
        },
        addNewGlobalNotification(val){
        //val is the props for SimplifiedNotification.vue
            this.global_notifications.push(val);
        },
        removeGlobalNotification(idx){
            this.global_notifications.splice(idx,1); 
        },
        addNewScheduleInfoInSchedules(val){
            //add the new schedule at the beginning of the list 
            this.schedules = [val, ...this.schedules];
        },
        updateExistingScheduleInfoInSchedules(val, idx){
            this.schedules[idx]=val;
        },
        updatePickupTimeSectionRequiredInputFields(val, operation_type){
            //operation_type: ENUM -> remove, add
            if(operation_type == "remove"){
                this.pickup_time_section_required_input_fields = this.pickup_time_section_required_input_fields.filter(item => item!=val);
            }
            if(operation_type == "add"){
                this.pickup_time_section_required_input_fields.push(val);
            }
        },
        updateEditPickupAddressRequiredInputFields(val, operation_type){
            //operation_type: ENUM -> remove, add
            if(operation_type == "remove"){
                this.edit_pickup_address_required_input_fields = this.edit_pickup_address_required_input_fields.filter(item => item!=val);
            }
            if(operation_type == "add"){
                this.edit_pickup_address_required_input_fields.push(val);
            }
        },
        updateEditPickupAddressInvalidValueInputFields(val, operation_type){
            //operation_type: ENUM -> remove, add
            if(operation_type == "remove"){
                this.edit_pickup_address_invalid_value_input_fields = this.edit_pickup_address_invalid_value_input_fields.filter(item => item!=val);
            }
            if(operation_type == "add"){
                this.edit_pickup_address_invalid_value_input_fields.push(val);
            }
        },
        updateEditContactRequiredInputFields(val, operation_type){
            //operation_type: ENUM -> remove, add
            if(operation_type == "remove"){
                this.edit_contact_required_input_fields = this.edit_contact_required_input_fields.filter(item => item!=val);
            }
            if(operation_type == "add"){
                this.edit_contact_required_input_fields.push(val);
            }
        },
        updateEditContactInvalidValueInputFields(val, operation_type){
            //operation_type: ENUM -> remove, add
            if(operation_type == "remove"){
                this.edit_contact_invalid_value_input_fields = this.edit_contact_invalid_value_input_fields.filter(item => item!=val);
            }
            if(operation_type == "add"){
                this.edit_contact_invalid_value_input_fields.push(val);
            }
        },
        setPatientInfoOnFile(val){
            this.patient_info_on_file = val;
        },
        initNewScheduleInfo(){
            this.new_schedule_info = {
                pickup_address:{...this.patient_info_on_file.address},
                contact: JSON.parse(JSON.stringify(this.patient_info_on_file.contact)),
                pickup_info:{
                    pickup_date:"",
                    ready_time:"",
                    customer_close_time:"",
                    remarks:"",
                },
                reminder: JSON.parse(JSON.stringify(this.patient_info_on_file.contact)),
                // default to be checked, as is_no_reminder_needed_checked default to be false
                // is_no_reminder_needed_checked:false,
                no_reminders_needed:false,
                remind_by_email_one_day_before_pickup: true, 
                remind_by_email_one_hour_before_pickup: true, 
                remind_by_phone_one_day_before_pickup: true, 
                remind_by_phone_one_hour_before_pickup: true 
            }
        },
        setCountryOptionsForPhoneInput(val){
            this.country_options_for_phone_input=val;
        },
        convertBackendScheduleInfo(schedule_info){
            const output = {...schedule_info, ...schedule_info.sender};
            output['contact'] = {
                email:output['contact_email'],
                phone:output['contact_phone'],
            }
            //reminder might be null value from backend
            output['reminder'] = {
                email:output['reminder_email'] ? output['reminder_email'] : '',
                phone:{
                    area_code: output['reminder_phone'] && output['reminder_phone'].area_code ? output['reminder_phone'].area_code : '',
                    phone_number: output['reminder_phone'] && output['reminder_phone'].phone_number ? output['reminder_phone'].phone_number : '',
                }
            }
            delete output['sender'];
            //convert pickup_info's: ready_time, customer_close_time
            output['pickup_info']['ready_time'] = moment(output['pickup_info']['ready_time'], 'HH:mm:ss').format('hh:mm A');
            output['pickup_info']['customer_close_time'] = moment(output['pickup_info']['customer_close_time'], 'HH:mm:ss').format('hh:mm A');
            //parse status: 
            //if status isn't "Cancelled": as long as the pickup_date is before the current date, we change status to be "Expired"
            if(
                output['status']!='Cancelled'
                &&
                moment(output['pickup_info']['pickup_date']).isBefore(moment(),'day')
            ){
                output['status']='Expired';
            }
            return output;
        },
        // actions
        checkContactValidation(contact_object){
            var is_current_contact_valid = true;
            var validation_details = {
                is_email_valid:true,
                is_phone_valid:true,
            };
            this.edit_contact_invalid_value_input_fields = [];
            //check whether email is valid
            const cur_email = contact_object.email;
            const match = cur_email.match(/(\w+([.-_^?$&!%*'+-/=`{|]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)/g);
            //if invalid email
            if(match == null){
                console.log('invalid email');
                is_current_contact_valid = false;
                validation_details['is_email_valid'] = false;
            }
            //check whether phone is valid:
            //1. has valid area_code: can find in country_code_to_area_code_map and all digits
            //2. valid phone_number: all digits
            //3. total digits length in range 5 to 15.
            const phone = contact_object.phone;
            if(!(
                    phone 
                    && phone.area_code
                    && Object.values(country_code_to_area_code_map).includes(phone.area_code)
                    && phone.phone_number 
                    && /^\d+$/.test(phone.area_code) 
                    && /^\d+$/.test(phone.phone_number)
                    && (phone.area_code.length+phone.phone_number.length>=5)
                    && (phone.area_code.length+phone.phone_number.length<=15)
            )){
                console.log('invalid phone');
                is_current_contact_valid = false;
                validation_details['is_phone_valid'] = false;
            }
            //4. special check if pass the previous standards:
            // if area_code == '1', the phone_number should be 10 digits format
            if(is_current_contact_valid && phone.area_code =='1' && phone.phone_number.length != 10){
                console.log('invalid phone');
                is_current_contact_valid = false;
                validation_details['is_phone_valid'] = false;
            }
            return {
                is_current_contact_valid,
                validation_details,
            };
        },
        async checkAndGetTokenForInternalRole(){
            // 1. if role == internal: need to have both internal_token and token
            if(this.role == "internal"){
                if(!this.internal_token){
                // 1.1. first check whether has internal_token: throw an error
                // NOTES: internal_token is REQUIRED, if role == 'internal'
                    return Promise.reject({
                        code:401,
                        message: 'No valid internal token for internal role.'
                    });
                }else if(!this.token){
                // 1.2. if has internal_token, but no token (PNS token), need to get the token (PNS token) via internal token
                    try{
                        const pns_token  = await this.getPNSTokenViaLISToken(this.internal_token);
                        //store the pns token in fedexPickupStore
                        this.token = pns_token;
                    }catch(err){
                        console.log('get pns token via internal token err in FedexPickupSearchPage: ', err);
                        // throw an error
                        return Promise.reject({
                            code:401,
                            message: 'No valid pns token for internal role.'
                        });
                    }
                }
            }
        },
        // call api request
        async checkAddressValidation(address_object, skip_format_and_completion_check = true){
            var is_current_address_valid = true;
            try{
                //check basic format and whether all required inputs are filled - 
                //NOTES: used when not pre-limit content through the ShippingAddressInputUnit.vue (for default address)
                //eg. when initially check address validation when open SchedulePickupDialog -> skip_format_and_completion_check = false
                if(!skip_format_and_completion_check){
                    //check whether PO box:
                    const regex =  /.*(P\.?O\.?\s+Box|Post\s+Office\s+Box)\s+((#|number|num)\s*)?\d+/igm;
                    if (address_object && address_object.street.match(regex)) {
                        is_current_address_valid = false;
                    }else {
                    //check whether complete address (all inputs required)
                        is_current_address_valid = address_object.street && address_object.city && address_object.state_or_province_code && address_object.country_code && address_object.postal_code;
                    }
                }
                var has_recommended_address = false;
                var recommended_address_object = {};
                if(is_current_address_valid){
                    //call validation api
                    const params = {
                        "addresses": [
                            {
                                "street_lines": [address_object.street],
                                "city": address_object.city,
                                "state_or_province_code": address_object.state_or_province_code,
                                "postal_code": address_object.postal_code,
                                "country_code": address_object.country_code,
                            }
                        ]
                    };
                    const res = await fedexPickupBaseURL.post('/pickups/v2/addresses/validation', params,
                        {
                            headers:{
                                Authorization:`Bearer ${this.token}`
                            }
                        }
                    );
                    const returned_address_object = res.data.resolved_addresses && res.data.resolved_addresses.length > 0 
                    ?
                    res.data.resolved_addresses[0]
                    :
                    {};
                    if(returned_address_object.customer_messages && returned_address_object.customer_messages.length > 0 && returned_address_object.customer_messages[0].code == 'STANDARDIZED.ADDRESS.NOTFOUND'){
                        is_current_address_valid = false;
                    }else{
                    // is_current_address_valid still true
                    // need to check whether the returned address is different from the one we sent:
                        const concate_returned_address = `${returned_address_object.street_lines_token[0]}, ${returned_address_object.city}, ${returned_address_object.state_or_province_code}, ${returned_address_object.postal_code}, ${returned_address_object.country_code}`;
                        const concate_typed_in_address = `${address_object.street}, ${address_object.city}, ${address_object.state_or_province_code}, ${address_object.postal_code}, ${address_object.country_code}`;
                        //if different: has recommend address, and assign the returned value as the recommend address
                        if(concate_returned_address!=concate_typed_in_address){
                            has_recommended_address = true;
                            recommended_address_object = {
                                street:returned_address_object.street_lines_token[0],
                                city:returned_address_object.city,
                                state_or_province_code:returned_address_object.state_or_province_code,
                                postal_code:returned_address_object.postal_code,
                                country_code:returned_address_object.country_code
                            }
                        }
                    }
                }
                return Promise.resolve({
                    is_current_address_valid: is_current_address_valid,
                    has_recommended_address: has_recommended_address, 
                    recommended_address: recommended_address_object,
                });
            }catch(err){
                return Promise.reject(err);
            }
        },
        async getCountryOptions(){
            try{
                const res = await fedexPickupOrderBaseURL.get('/orderTest/getCountryList',{
                    headers:{ 
                        Authorization: `Bearer ${this.token}`
                    }
                });
                return Promise.resolve(res.data);
            }catch(err){
                console.log('getCountryOptions api error:', err);
                return Promise.reject(err);
            }
        },
        async getPNSTokenViaLISToken(internal_token){
            const params = {
                accession_id:this.accession_id
            }
            try{
                const res = await sampleBaseURL.get('/patients/pns-jwt',{
                    params,
                    headers:{ 
                        Authorization: `Bearer ${internal_token}`
                    }
                });
                return Promise.resolve(res.data.data.pns_jwt);
            }catch(err){
                console.log('getPNSTokenViaLISToken api error:', err);
                return Promise.reject(err);
            }
        },
        async getCustomerInfo(){
        // get patient_id for later getting patient_info_on_file
            try{
                const params = {
                    jwtToken: this.token,
                }
                const res = await fedexPickupOrderBaseURL.post('/customer/getCustomerInfo', params,{
                    headers:{
                        Authorization:`Bearer ${this.token}`,
                    }
                });
                this.patient_id = res.data.patient_id;
                localStorage.setItem('patient_id', this.patient_id);
                console.log('getCustomerInfo patient_id', this.patient_id);
                return Promise.resolve(res.data);
            }catch(err){
                console.log('getCustomerInfo error:', err);
                return Promise.reject(err);
            }
        },
        async getPatientInfoOnFile(){
        //get patient_info_on_file and parse the result as needed
            try{
                const params = {
                    patientId:this.patient_id ? this.patient_id : localStorage.getItem('patient_id'),
                };
                console.log('getPatientInfoOnFile', params);
                const response = await fedexPickupOrderBaseURL.get('/orderTest/patient', {
                    params,
                    headers:{
                        Authorization:`Bearer ${this.token}`
                    }
                });
                const res = response.data;
                //parse person_name for use of creating new schedules
                res['person_name'] = `${res.patient_first_name} ${res.patient_last_name}`;
                //parse and convert patient_contact for patient_info_on_file 
                var converted_patient_contact={
                    email:'',
                    phone:{
                        area_code:'',
                        phone_number:'',
                    }
                };
                if(res.patient_contact){
                    //iterate over this.patient_info_on_file.patient_contact:
                    for(const contact_method of res.patient_contact){
                        // if contact_type == "email":
                        if(contact_method.contact_type == "email"){
                            converted_patient_contact['email'] = contact_method.contact_details;
                        }else if(contact_method.contact_type == "phone"){
                        // if contact_type == "phone":
                            //parse to figure out area_code and phone_number
                            // 1. Remove parentheses
                            const phone_without_parentheses = contact_method.contact_details.replace(/[()]/g, '');
                            // 2. check if phone from backend in the format: 
                            //      1) Start with an optional '+'. 
                            //      2) Followed by all digits, up to a maximum length of 3
                            //      3) Followed by either a - or a space (non-capturing group, the separator is matched, but not captured in the result)
                            //      4) Followed by more all digits
                            const check_phone_format_regex = /^\+?(\d{1,3})(?:[-\s](\d+))$/;;
                            const match_the_format = phone_without_parentheses.match(check_phone_format_regex);
                            // 3. if the format is matched:
                            if(match_the_format){
                                const area_code = match_the_format[1];
                                const phone_number = match_the_format[2];
                                //3.1 if find area_code in country_code_to_area_code_map
                                if(Object.values(country_code_to_area_code_map).includes(area_code)){
                                    // 3.1.1 assign the area_code and phone_number accordingly
                                    converted_patient_contact['phone'] = {
                                        area_code: area_code,
                                        phone_number: phone_number,
                                    };
                                }else{
                                //3.2 if cannot find area_code in country_code_to_area_code_map:
                                    // 3.2.1 assign the area_code with empty string and phone_number accordingly
                                    converted_patient_contact['phone'] = {
                                        area_code: '',
                                        phone_number: phone_number,
                                    };
                                }
                            }else{
                            //if the format isn't matched:
                                // 1. Remove spaces, dashes, and parentheses for consistency
                                const cleaned_phone = contact_method.contact_details.replace(/[\s-()]/g, '');
                                converted_patient_contact['phone'] = {
                                    area_code:'',
                                    phone_number:cleaned_phone,
                                };
                                if(cleaned_phone){
                                    //if begin with 1, 1-, or +1:
                                    const start_with_US_area_code = /^(\+1|1-?|1)/.test(cleaned_phone);
                                    if(start_with_US_area_code){
                                        // strip the beginning part if it starts with +1, 1-, or 1
                                        const part_left = cleaned_phone.replace(/^(\+1|1-?|1)/, '');
                                        //if the part_left is a 10 digits only string:
                                        if(part_left.length == 10 && /^\d+$/.test(part_left)){
                                            // consider as US phone: area_code:"1", phone_number:the last 10 digits
                                            converted_patient_contact['phone'] = {
                                                area_code:'1',
                                                phone_number: part_left,
                                            }
                                        }
                                    }
                                    //else: area_code:"", phone_number: cleaned contact_method.contact_details
                                }
                            }
                        }
                    }
                }
                res['contact'] = converted_patient_contact;
                delete res['patient_contact'];
                //parse and convert patient_address for patient_info_on_file 
                var converted_patient_address={
                    street:'',
                    city:'',
                    state_or_province_code:'',
                    postal_code:'',
                    country_code:''
                };
                if(res.patient_address && res.patient_address.length > 0){
                    const converted_country_code_on_file = getCountryCode(res.patient_address[0].country);
                    converted_patient_address = {
                        street: res.patient_address[0].street_address,
                        city:res.patient_address[0].city,
                        //parse the state on file in advance to match fedex two-letter rule
                        state_or_province_code:res.patient_address[0].state.length > 2 ? '' : res.patient_address[0].state,
                        postal_code:res.patient_address[0].zipcode,
                        //parse the country on file in advance to be country code
                        country_code: converted_country_code_on_file.length !=2 ? '' : converted_country_code_on_file,
                    }
                }
                res['address'] = converted_patient_address;
                delete res['patient_address'];
                this.patient_info_on_file = {...res};
                this.initNewScheduleInfo();
                return Promise.resolve(res);
            }catch(err){
                console.log('getPatientInfoOnFile error:', err);
                return Promise.reject(err);
            }
        },
        async getCurrentAccessionIdFedexPickups(accession_id){
            try{
                const params = {
                    accession_id:accession_id
                }
                const res = await fedexPickupBaseURL.post('/pickups/v2/schedule/retrieval', params, {
                    headers:{
                        Authorization: `Bearer ${this.role == 'internal' ? this.internal_token : this.token}`
                    }
                });
                const converted_schedules = res.data.pickup_schedules.map(item=>{
                    return this.convertBackendScheduleInfo(item);
                });
                //sort converted_schedule in ascending order of create_time
                converted_schedules.sort((a,b)=>{
                    const a_create_time = moment(a.create_time);
                    const b_create_time = moment(b.create_time);
                    if(a_create_time.isSame(b_create_time)){
                        return 0;
                    }
                    return a_create_time.isAfter(b_create_time) ? -1 : 1;
                });
                this.schedules = converted_schedules;
            }catch(err){
                console.log('getSchedulesOfSample error:', err);
                return Promise.reject(err);
            }
        },
        async getAvailabilitiesBasedOnAddressAndDate(address_object, date){
            try{
                const params = {
                    pickup_address:{
                        street: address_object.street,
                        city: address_object.city,
                        // two-letter state or province code, required, already limited and check at the input stage
                        state_or_province_code: address_object.state_or_province_code, 
                        postal_code: address_object.postal_code,
                        //country input already limited via selector with fixed options, but use getCountryCode() for double check.
                        country_code: address_object.country_code,
                    },
                    // Enum: 'DOMESTIC' or 'INTERNATIONAL'
                    // NOTES: hardcode as 'DOMESTIC' due to fedex issue, error when use 'INTERNATIONAL'
                    country_relationship: "DOMESTIC", 
                    pickup_date: moment(date).format('YYYY-MM-DD'), // “Express Pickup is only for current or next business day”
                }
                const res = await fedexPickupBaseURL.post('/pickups/availabilities', params);
                return Promise.resolve(res.data.availabilities);
            }catch(err){
                console.log('getAvailabilitiesBasedOnAddressAndDate err', err);
                return Promise.reject(err);
            }
        },
        async createNewSchedule(schedule_info, save_patient_address = false, save_patient_contact_phone = false){
        //create a new schedule based on the schedule_info passed
            try{
                const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const params ={ 
                    // the client that fedex will go pickup his package
                    sender: {
                        role: "patient", // Required
                        accession_id: this.accession_id, // Required the accession of the sample
                        person_name: `${this.patient_info_on_file.person_name}`, // the name for fedex courier to contact
                        contact_phone: schedule_info.contact.phone, // Nested phone details
                        // eg. contact_phone: {    "area_code": "1", // Required, area code of contact phone
                        //     "phone_number": "2223334445" // Required, phone number for FedEx courier to contact
                        // }
                        contact_email: schedule_info.contact.email, // Optional, valid email format, not empty string if provided
                        no_reminders_needed: schedule_info.no_reminders_needed,
                        save_patient_contact_phone:save_patient_contact_phone,
                    },
                    pickup_address: {
                        ...schedule_info.pickup_address,
                        // eg. "street": "1021 Howard Ave, Suite B",
                        //     "city": "San Carlos",
                        //     "postal_code": "94070",
                        //     "state_or_province_code": "CA",
                        //     "country_code": "US",
                        save_patient_address:save_patient_address,
                        residential: true // optional, the default is false
                    },
                    timezone:time_zone,
                    pickup_date: schedule_info.pickup_info.pickup_date, // the date when package is ready for pick up. format YYYY-MM-DD
                    ready_time: moment(schedule_info.pickup_info.ready_time,'hh:mm A').format('HH:mm:ss'), // the time when package is ready for pick up. format HH:MM:SS
                    customer_close_time: moment(schedule_info.pickup_info.customer_close_time,'hh:mm A').format('HH:mm:ss'), // the latest time when package can be picked up. Format HH:MM:SS.
                    // "carrier_code": "FDXE", // optional. By default it is 'FDXE'. Enum: 'FDXE' or 'FDXG'
                    // "package_count": 1, // optional
                    // "total_weight": { // optional
                    //     "units": "LB", // Enum: 'KG', 'LB'
                    //     "value": 1
                    // },
                    remarks: schedule_info.pickup_info.remarks, // optional. Message to be passed to the FedEx pickup courier.
                };
                //if not check the no_reminders_needed checkbox: add the related fields
                if(!schedule_info.no_reminders_needed){
                    //only pass email related value of the reminder section, if any reminder checkbox via email method is checked:
                    //  -> the reminder_email is definitely valid, and assign the values for related fields, 
                    if(schedule_info.remind_by_email_one_day_before_pickup || schedule_info.remind_by_email_one_hour_before_pickup){
                        params.sender['reminder_email']= schedule_info.reminder.email; // Optional, valid email format if provided
                        params.sender['remind_by_email_one_day_before_pickup']= schedule_info.remind_by_email_one_day_before_pickup; // Optional, boolean flag
                        params.sender['remind_by_email_one_hour_before_pickup']= schedule_info.remind_by_email_one_hour_before_pickup; // Optional, boolean flag
                    }
                    //only pass phone related value of the reminder section, if any reminder checkbox via phone method is checked:
                    //  -> the reminder_phone is definitely valid, and assign the value for related fields
                    if(schedule_info.remind_by_phone_one_day_before_pickup || schedule_info.remind_by_phone_one_hour_before_pickup){
                        params.sender['reminder_phone']= schedule_info.reminder.phone; // Optional, nested phone details (same structure as contact.phone field)
                        params.sender['remind_by_phone_one_day_before_pickup']= schedule_info.remind_by_phone_one_day_before_pickup; // Optional, boolean flag
                        params.sender['remind_by_phone_one_hour_before_pickup']= schedule_info.remind_by_phone_one_hour_before_pickup; // Optional, boolean flag
                    }
                }
                // console.log('createNewSchedule add reminder params', params);
                const res = await fedexPickupBaseURL.post('/pickups/v2/schedule', params, {
                    headers:{ 
                        Authorization: `Bearer ${this.role == 'internal' ? this.internal_token : this.token}`
                    },
                });
                
                return Promise.resolve(this.convertBackendScheduleInfo(res.data.pickup_schedule));
                // return Promise.resolve(this.convertBackendScheduleInfo(test_schedules.pickup_schedules[0]));
            }catch(err){
                console.log('createNewSchedule Error:', err);
                return Promise.reject(err);
            }
        },
        async cancelExistingSchedule(schedule_info){
        //cancel an existing schedule, schedule_info is the targeted schedule
            try{
                const params = {
                    schedule_id: schedule_info.schedule_id,
                };
                const res = await fedexPickupBaseURL.put('/pickups/v2/schedule/cancel', params,
                    {
                        headers:{
                            Authorization:`Bearer ${this.role == 'internal' ? this.internal_token : this.token}`
                        }
                    }
                );
                return Promise.resolve(this.convertBackendScheduleInfo(res.data.pickup_schedule));
            }catch(err){
                console.log('cancelExistingSchedule Error:', err);
                return Promise.reject(err);
            }
        }
    }
})