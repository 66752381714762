import axios from "axios";
import { ElNotification } from "element-plus";
export const patientService = axios.create({
  baseURL:
    process.env.VUE_APP_ENV == "production"
    // staging api not available
      ? "https://api.vibrant-wellness.com/v1/portal/order/patientPage"
      : "https://api.vibrant-wellness.com/v1/portal/order/staging/patientPage"
});

let notificationInstance = null;
let notFoundNotificationInstance = null;
let serverErrorNotification = null;
let unauthorizedNotification = null;

patientService.interceptors.request.use(
  function (config) {
    const token = `Bearer ${localStorage.getItem("pnsToken")}`;
    if (token) config.headers["Authorization"] = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

patientService.interceptors.response.use(
  (response) => {
    if (response.status !== 200 && response.status !== 201) {
      if (!notificationInstance) {
        notificationInstance = ElNotification({
          title: "Failed",
          type: "error",
          customClass: "errorNotification",
          message: response.message,
          duration: 0,
          onClose: () => {
            notificationInstance = null;
          },
        });
      }
      return Promise.reject("error");
    } else {
      return response;
    }
  },
  (error) => {
    const { status } = error.response;
    if (status === 404) {
      if (!notFoundNotificationInstance) {
        notFoundNotificationInstance = ElNotification({
          title: "Failed",
          message: "Not Found",
          customClass: "errorNotification",
          type: "error",
          duration: 3500,
          onClose: () => {
            notFoundNotificationInstance = null;
          },
        });
      }
    } else if (status === 401) {
      if (!unauthorizedNotification) {
        unauthorizedNotification = ElNotification({
          title: "Failed",
          message: "Unauthorized",
          customClass: "errorNotification",
          type: "error",
          duration: 3500,
          onClose: () => {
            unauthorizedNotification = null;
          },
        });
      }
    } else if (status >= 500 && status < 600) {
      console.log(status, "status");
      if (!serverErrorNotification) {
        serverErrorNotification = ElNotification({
          title: "Failed",
          message: "Server Error",
          customClass: "errorNotification",
          type: "error",
          duration: 3500,
          onClose: () => {
            serverErrorNotification = null;
          },
        });
      }
    } else {
      const errorMessage = error.response.data.description;

      if (!notificationInstance) {
        notificationInstance = ElNotification({
          title: "Failed",
          message: errorMessage,
          customClass: "errorNotification",
          type: "error",
          duration: 3500,
          onClose: () => {
            notificationInstance = null;
          },
        });
      }
    }
    return Promise.reject(error);
  }
);

export default patientService;
