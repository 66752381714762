<template>
  <div id="page-container" v-show="showSucceedPage">
    <div class="pageBodyContainer">
      <img
        class="paymentStatusIcon"
        :src="dynamic_payment_success_icon"
        alt="payment successful"
      />
      <div class="paymentStatusTitle">Payment Successful</div>
      <div class="paymentStatusInfo">
        The payment has been done successfully.<br />Thanks for being there with
        us.
      </div>
      <!-- <div class="successBanner" v-if="!this.isTestCustomerId">
        <div class="font-bold">What's the Next:&nbsp;</div>
        <div class="bannerMessage">
          We will send your kit as soon as possible
        </div>
      </div> -->

      <div
        class="redirectBanner"
        v-if="this.showNextStep"
        @click="goToNextStep()"
      >
        <div class="font-bold">What's the Next:&nbsp;</div>
        <div class="bannerMessage">
          {{ dynamic_next_step_message }}
        </div>
      </div>

      <!-- <div
        class="redirectBanner"
        v-if="this.addressNeeded && this.showNextStep"
        @click="goToNextStep('address')"
      >
        <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div>
      <div
        class="redirectBanner"
        v-if="!this.addressNeeded && this.bloodDrawNeeded && this.showNextStep"
        @click="goToNextStep('bloodDraw')"
      >
      <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div>
      <div
        class="redirectBanner"
        v-if="!this.addressNeeded && !this.bloodDrawNeeded && this.showNextStep"
        @click="goToNextStep('collectionTutorial')"
      >
        <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState } from "pinia";
import payment_success_pink_icon from "@/assets/payment/success.svg";
import payment_success_yellow_icon from "@/assets/payment/pending.svg";
import { isProd } from "@/components/bloodDraw/BloodDrawData/request";
export default {
  name: "PaymentSucceed",
  data() {
    return {
      token: localStorage.getItem("pnsToken"),
      sampleId: localStorage.getItem("sample_id"),
      customerId: localStorage.getItem("customer_id"),
      accessionId: localStorage.getItem("accession_id"),
      patientId: localStorage.getItem("patient_id"),
      chargeMethod: localStorage.getItem("charge_method"),
      addressNeeded: localStorage.getItem("address_needed") == "true",
      bloodDrawNeeded: localStorage.getItem("blood_draw_needed") == "true",
      conciergeNeeded: localStorage.getItem("concierge_needed") == "true",
      showSucceedPage: false,
      payFixedAmount: localStorage.getItem("amount_to_pay") ? true : false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (
      this.billingInfo &&
      Number(this.billingInfo.concierge_fee) > 0 &&
      this.conciergeNeeded
    ) {
      const zhang_jun_base_url = isProd ? 'https://phleb.vibrant-wellness.com/app/concierge-request/concierge':'https://phleb.vibrant-wellness.com/app/staging/concierge-request/concierge';
      const redirectUrl = `${zhang_jun_base_url}/${this.accessionId}/confirmPage?payment=success`;
      console.log("redirectUrl", redirectUrl);
      window.location.replace(redirectUrl);
      return;
    }
    this.showSucceedPage = true;
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo",
      'patient_details',
      'address_needed',
    ]),
    showNextStep() {
      return (
        this.billingInfo &&
        // Number(this.billingInfo.concierge_fee) <= 0 &&
        !this.payFixedAmount
      );
    },
    isTestCustomerId() {
      return (
        this.customerId == 999997 ||
        this.customerId == 29520 ||
        this.customerId == 29818 ||
        this.customerId == 31157 ||
        this.customerId == 12005 ||
        this.customerId == 1000002
      );
    },
    is_missing_DOB_or_gender(){
      return !(this.patient_details && this.patient_details.patient_gender && this.patient_details.patient_birthdate);
    },
    is_kit_shipped_to_patient(){
      return this.address_needed;
    },
    dynamic_payment_success_icon(){
      return this.is_missing_DOB_or_gender
      ? payment_success_yellow_icon
      : (
        this.is_kit_shipped_to_patient
        ? payment_success_yellow_icon
        : payment_success_pink_icon
      )
    },
    dynamic_next_step_message(){
      return this.is_missing_DOB_or_gender
      ? 'Fill In your Basic Info to Unlock your Order'
      : (
        this.is_kit_shipped_to_patient
        ? 'Confirm your Shipment'
        :'Review your Info & Sample Collection Tutorial'
      )
    }
  },
  methods: {
    goToNextStep(){
      // express v2 flow: definitely redirect to shipping. either confirm basic information or confirm both basic information and shipping address.
      this.$router.push(
          `uploadShippingAddress?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&bloodDrawNeeded=${this.bloodDrawNeeded}&patient_id=${this.patientId}&address_needed=${this.addressNeeded}`
      );

      // ------- previous flow: conditionally redirect to different pages (temporary save as record) ----------
      // switch (step) {
      //   case "address":
      //     // redirectUrl = `https://pns.vibrant-wellness.com/uploadShippingAddress?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&bloodDrawNeeded=${this.bloodDrawNeeded}&patient_id=${this.patientId}`;
      //     this.$router.push(
      //       `uploadShippingAddress?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&bloodDrawNeeded=${this.bloodDrawNeeded}&patient_id=${this.patientId}`
      //     );
      //     break;
      //   case "bloodDraw":
      //     // redirectUrl = `https://pns.vibrant-wellness.com/bloodDraw?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&patient_id=${this.patientId}`;
      //     this.$router.push(
      //       `bloodDraw?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&patient_id=${this.patientId}`
      //     );
      //     break;
      //   // NOTE: currently this case "collectionTutorial" is restrcited by other upper-level business logic (order test): all non-blood kits will be sent to patient
      //   // so if it is nonblood kit: it will assurely go to case "address"
      //   case "collectionTutorial":
      //     // redirectUrl = "https://www.vibrant-wellness.com/collection/";
      //     window.location.href = "https://www.vibrant-wellness.com/collection/";
      //     break;
      // }
      // ------- previous flow: conditionally redirect to different pages (temporary save as record) ---------- end
    },
  },
}
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  position: relative;
}

.successBanner {
  width: 767px;
  height: 80px !important;
  border-radius: 4px;
  background: #20a8c3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
}

.redirectBanner {
  width: 767px;
  height: 80px !important;
  border-radius: 4px;
  background: #20a8c3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.redirectBanner:hover {
  background: #30c0db;
}

@media only screen and (max-width: 767px) {
  .successBanner {
    width: 320px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .redirectBanner {
    width: 320px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .successBanner .bannerMessage::before {
    content: "\A";
  }
}
</style>
