<template>
  <div class="checkboxTitle">
    {{ content }}
    <span v-if="isRequired" class="required">*</span>
  </div>
  <el-checkbox-group
    v-model="selectedOption"
    @change="onChange"
    class="checkboxGroup"
  >
    <el-checkbox
      v-for="option in options"
      :key="option.value"
      :label="option.value"
      border
    >
      {{ option.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  name: "QuestionnaireMultiChoice",
  props: {
    isRequired: {
      type: Boolean,
    },
    content: {
      type: String,
      required: true,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: "",
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedOption = newValue;
      },
    },
  },
  emits: ["onChange"],
  data() {
    return {
      selectedOption: this.value,
      checkboxHeight: this.height === "tall" ? "80px" : "42px",
      checkboxWidth: this.width === "wide" ? "27vw" : this.width,
    };
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.selectedOption);
    },
  },
};
</script>

<style scoped>
.checkboxTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.175px;
  margin-bottom: 8px;
}

.required {
  color: #ff1d00;
}

.el-checkbox-group {
  display: flex;
  /* gap:15px; */
  padding: 0px;
  box-sizing: border-box;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 18px;
}

:deep(.el-checkbox.is-bordered) {
  width: v-bind("checkboxWidth");
  /* min-width: 284px; */
  min-height: v-bind("checkboxHeight");
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #bcccdc;
  margin-right: 0px;
}

:deep(.el-checkbox__label) {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.46px;
  color: #000000;
  white-space: normal;
}

:deep(.el-checkbox.is-bordered.is-checked) {
  border: 1px solid #20a8c3;
  background-color: rgba(32, 168, 195, 0.1);
}

:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #20a8c3;
}

:deep(.el-checkbox__inner) {
  height: 20px !important;
  width: 20px !important;
  border: 2px #000000 solid;
  margin-left: 7px;
  margin-right: 7px;
}

:deep(.el-checkbox__input.is-disabled .el-checkbox__inner) {
  border: 2px #bcccdc solid;
  background: transparent !important;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  border: 2px #20a8c3 solid !important;
  background: transparent !important;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%) rotate(45deg);
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #20a8c3 !important;
  border-color: #20a8c3 !important;
  position: relative;
}


@media only screen and (max-width: 808px) {
  .el-checkbox-group {
    flex-direction: column;
  }
  :deep(.el-checkbox.is-bordered) {
    min-height: 42px;
    height: fit-content;
    width: v-bind("radioWidth");
  }

  .checkboxTitle {
    font-size: 14px;
  }

  :deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .el-checkbox div {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  :deep(.el-checkbox__inner) {
    height: 16px !important;
    width: 16px !important;
    margin-left: 12px;
    margin-right: 12px;
    border: 1px #000000 solid !important;
  }

  :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
    width: 8px;
    height: 8px;
    border: 1px #20a8c3 solid !important;
  }
}
</style>
