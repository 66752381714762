import { defineStore } from "pinia";
import * as Sentry from "@sentry/vue";

export const useCommonStore = defineStore("CommonStore", {
  state: () => {
    return {
      sample_id: localStorage && localStorage.getItem("sample_id")
        ? localStorage.getItem("sample_id")
        : "",
      accession_id: localStorage && localStorage.getItem("accession_id")
        ? localStorage.getItem("accession_id")
        : "",
      show_vw_loading_animation:false,
    };
  },
  actions: {
    addPatientIDToSentry(patientId){
      if (patientId) {
        Sentry.setUser({ patient_id: patientId }); // Stays across events
      }
    },
    setSampleId(val) {
      if (val) {
        localStorage.setItem("sample_id", val);
        this.sample_id = val;
      } else {
        localStorage.removeItem("sample_id");
        this.sample_id = "";
      }
    },
    setAccessionId(val) {
      if (val) {
        localStorage.setItem("accession_id", val);
        this.accession_id = val;
      } else {
        localStorage.removeItem("accession_id");
        this.accession_id = "";
      }
    },
    setJwtToken(val) {
      console.log('setJwtToken CommonStore', val);
      if (val) {
        localStorage.setItem("pnsToken", val);
      } else {
        console.log('remove pnsToken CommonStore');
        localStorage.removeItem("pnsToken");
      }
    },
    setShowVWLoadingAnimation(val){
      this.show_vw_loading_animation=val;
    }
  },
});
