<template>
  <div id="page-container"></div>
</template>

<script>
import { useCommonStore } from "@/stores/CommonStore.ts";
import { mapActions } from "pinia";
export default {
  name: "QuestionnairePage",
  props: {
    accession_id: String,
    jwtToken: String,
    sample_id: String,
  },
  created() {
    this.setAccessionId(this.accession_id);
    this.setJwtToken(this.jwtToken);
    this.setSampleId(this.sample_id);
    console.log("accession_id", this.accession_id);
    console.log("jwtToken", this.jwtToken);
    console.log("sample_id", this.sample_id);
    this.$router.push("/hormoneZoomer");
    // this.getQuestionnaireData().then((res) => {
    //   if (res.data.hormone_questionaire && res.data.hormone_questionaire.completed_at) {
    //     this.$router.push("/questionnaire_completed");
    //   } else {
    //     this.$router.push("/questionnaire_form");
    //   }
    // }).catch((err) => {
    //   console.log(err);
    // });
  },
  methods: {
    ...mapActions(useCommonStore, ["setAccessionId","setSampleId", "setJwtToken"]),
  },
};
</script>
