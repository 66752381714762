<template>
    <div id = 'blood-draw-view-container' v-loading="loading"></div>
</template>

<script>
import { mapActions } from "pinia";
import { useBloodDrawStore } from '@/stores/BloodDrawStore.ts';
import { useCommonStore } from "@/stores/CommonStore.ts";
export default{
    async mounted(){
        this.loading=true;
        //comment for staging test: more convenient to track the redirect link's data;
        console.log('BloodDrawView link', this.$route);
        if(this.$route.query && Object.keys(this.$route.query).length > 0){
            try{
                //set data in CommonStore: for the top header information
                this.setAccessionId(this.$route.query.accession_id);
                this.setSampleId(this.$route.query.sample_id);
                //set data in BloodDrawStore: for related apis after redirect to /bloodDrawLanding
                await this.storeAllQueryInfomation(this.$route.query);
                //save query info in localStorage: for user refresh on /bloodDrawLanding
                localStorage.setItem('blood_draw_page_query', JSON.stringify(this.$route.query));
                this.$router.push('/bloodDrawLanding');
            }catch(err){
                console.log('error in BloodDrawMedia mounted:',err);
                this.$router.push('/failed');
            }
        }else{
            //remove previous query info in localStorage: in case there is a previous record there
            localStorage.removeItem('blood_draw_page_query');
            this.$router.push('/failed');
        }
        this.loading=false;
    },
    data(){
        return{
            loading:false
        }
    },
    methods:{
        ...mapActions(useBloodDrawStore,[
            'storeAllQueryInfomation',
        ]),
        ...mapActions(useCommonStore,[
            'setAccessionId',
            'setSampleId',
        ]),
    }
}
</script>

<style scoped>
#blood-draw-view-container{
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 30px;

    /*need to explicitly set parent div for children div height to be dynamic*/ 
    height:calc(100vh - 92px);
}
@media only screen and (max-width: 767px){
    #blood-draw-view-container{
        padding-left: 28px;
        padding-right: 27px;
        margin-top: 40px;

        height:fit-content;
        min-height: calc(100vh - 80px);
    }
}
</style>