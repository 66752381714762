import { defineStore } from "pinia";
import { questionnaireService } from "@/utils/questionnaireRequest";
import router from "../routes";
import moment from "moment";

export const useHormoneZoomerStore = defineStore("HormoneZoomerStore", {
  state: () => {
    return {
      gender: "",
      age: "",
      lastMenstrualDate: "",
      accession_id: localStorage.getItem("accession_id")
        ? String(localStorage.getItem("accession_id"))
        : "",
      hormoneZoomerQnr: {
        accession_id: localStorage.getItem("accession_id")
          ? String(localStorage.getItem("accession_id"))
          : "",
        basic_info: {
          radical_prostatectomy: null,
          bladder_or_prostate_infection: null,
          last_menstrual_period: null,
          current_menstrual_phase: null,
          reproductive_health_stage: null,
          regular_periods: null,
          hysterectomy: null,
        },
        bone_health_and_toxic_exposure: {
          bone_density_scan: null,
          bone_density_result: null,
          experienced_fractures: null,
          toxic_substance_exposure: null,
        },
        symptom_assessment: {
          fatigue: null,
          loss_of_libido: null,
          erectile_dysfunction: null,
          loss_of_muscle_mass: null,
          increased_body_fat: null,
          depression_or_anxiety: null,
          difficulty_concentrating: null,
          poor_sleep: null,
          reduced_physical_performance: null,
          mood_swings: null,
          loss_of_hair: null,
          hot_flashes: null,
          vaginal_dryness: null,
          sleep_disturbances: null,
          joint_pain: null,
          urinary_problems: null,
        },
        medical_history: {
          personal_history: {
            hypogonadism: null,
            breast_cancer: null,
            ovarian_cancer: null,
            endometrial_cancer: null,
            stomach_cancer: null,
            pancreatic_cancer: null,
            colon_rectal_cancer: null,
            other_cancer: null,
          },
          comorbidities: {
            type2_diabetes: null,
            liver_disease: null,
            obesity: null,
            cardiovascular_disease: null,
            sleep_apnea: null,
            prostate_conditions: null,
            infertility_issues: null,
            autoimmune_conditions: null,
            thyroid_conditions: null,
            hypertension: null,
            blood_clots: null,
            gallbladder_complications: null,
            other_comorbidities: null,
          },
          family_history: {
            family_cancer_history: null,
            family_prostate_cancer: null,
            family_breast_cancer: null,
            family_cardio_disease: null,
            family_cerebrovascular: null,
            family_autoimmune: null,
            family_venous_thrombus: null,
            family_thyroid_disease: null,
            family_hypertension: null,
            other_family_conditions: null,
          },
        },
        treatment_considerations: {
          hormone_treatment_preference: null,
          sensitive_skin: null,
          undergoing_hormone_therapy: null,
          peanut_allergy: null,
          current_medications: null,
          additional_information: null,
        },
      },
      personalHistoryArray: [],
      comorbiditiesArray: [],
      familyHistoryArray: [],
      loadingQuestionnaire: false,
      loadingSubmit: false,
    };
  },
  actions: {
    setHormoneZoomerQnr(val) {
      if (!val || typeof val !== "object") return;
      // Filter out any keys that are not in the template
      const filterKeys = (source, template) => {
        let result = {};
        for (const key in template) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            if (typeof template[key] === "object" && template[key] !== null) {
              result[key] = filterKeys(source[key], template[key]);
            } else {
              result[key] = source[key];
            }
          }
        }
        return result;
      };
      this.hormoneZoomerQnr = filterKeys(val, this.hormoneZoomerQnr);
      this.convertHormoneZoomerQnrObjectToArray();
    },
    async getZoomerQnrData() {
      const params = {
        accession_id: this.accession_id,
      };
      this.loadingQuestionnaire = true;
      try {
        const res = await questionnaireService.get(
          "/orders/samples/hormone-zoomer/questionnaires",
          {
            params: params,
          }
        );
        // if (
        //   res.data.data.hormone_questionaire &&
        //   res.data.data.hormone_questionaire.completed_at
        // ) {
        //   router.push("/questionnaire_completed");
        // }
        this.setAge(res.data?.basic_info?.age);
        this.setGender(res.data?.basic_info?.gender);
        this.dateConversion(res.data?.basic_info?.last_menstrual_period);
        if (res.data.completed_at) {
          this.setHormoneZoomerQnr(res.data);
          console.log("completed qnr", this.hormoneZoomerQnr);
        }

        // this.setHormoneZoomerQnr(res.data.data.hormone_questionaire);
        this.loadingQuestionnaire = false;
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to retrieve questionnaire", err);
        router.push("/hormoneZoomerFailed");
        this.loadingQuestionnaire = false;
        return Promise.reject(err);
      }
    },
    submitHormoneZoomerQnr(val) {
      this.loadingSubmit = true;
      let params = this.hormoneZoomerQnr;

      params.accession_id = String(this.accession_id);
      params.save_for_now = val;
      questionnaireService
        .post("/orders/samples/hormone-zoomer/questionnaires", params)
        .then((res) => {
          this.loadingSubmit = false;
          if (!val) {
            router.push("/questionnaire_completed");
          }
        })
        .catch((err) => {
          this.loadingSubmit = false;
          router.push("/hormoneZoomerFailed");
          console.log("submitQuestionnaire", err);
        });
    },
    // Set functions
    setAge(val) {
      this.age = val;
    },
    setGender(val) {
      this.gender = val;
    },
    setHormoneZoomerQnrSingleField(key, val) {
      // Split the provided key into an array by the dot (.) separator to handle nested keys
      const keys = key.split(".");
      // Start from the root object (hormoneZoomerQnr)
      let currentObj = this.hormoneZoomerQnr;
      // Iterate through all keys except the last one, to navigate through the nested objects
      for (let i = 0; i < keys.length - 1; i++) {
        // Move deeper into the object at each level
        if (!currentObj[keys[i]]) {
          // If the key doesn't exist at the current level, initialize it as an empty object
          currentObj[keys[i]] = {};
        }
        currentObj = currentObj[keys[i]];
      }
      // Update the value at the final key
      currentObj[keys[keys.length - 1]] = val;
    },
    setPersonalHistoryArray(val) {
      this.personalHistoryArray = val;
    },
    setComorbiditiesArray(val) {
      this.comorbiditiesArray = val;
    },
    setFamilyHistoryArray(val) {
      this.familyHistoryArray = val;
    },
    convertHormoneZoomerQnrObjectToArray() {
      this.personalHistoryArray = [];
      this.comorbiditiesArray = [];
      this.familyHistoryArray = [];
      // Iterate through the medical history object and push the keys that have a truthy value to the corresponding array
      for (const key in this.hormoneZoomerQnr.medical_history) {
        for (const field in this.hormoneZoomerQnr.medical_history[key]) {
          if (this.hormoneZoomerQnr.medical_history[key][field]) {
            if (field === "other_cancer") {
              this.personalHistoryArray.push("other");
            } else if (field === "other_comorbidities") {
              this.comorbiditiesArray.push("other");
            } else if (field === "other_family_conditions") {
              this.familyHistoryArray.push("other");
            } else {
              if (key === "personal_history") {
                this.personalHistoryArray.push(field);
              } else if (key === "comorbidities") {
                this.comorbiditiesArray.push(field);
              } else {
                this.familyHistoryArray.push(field);
              }
            }
          }
        }
      }
    },
    convertHormoneZoomerQnrArrayToObject(key, arr) {
      if (!(key in this.hormoneZoomerQnr.medical_history)) {
        console.log("key not found in medical_history");
        return;
      }
      // Iterate through the medical history object and set the value of each field to true if it's in the provided array
      for (const item in this.hormoneZoomerQnr.medical_history[key]) {
        if (!item.includes("other")) {
          this.hormoneZoomerQnr.medical_history[key][item] = arr.includes(
            item
          )
            ? true
            : false;
        }
      }
      // check the array, if user doesn't select 'other' then clear the other field
      if(!arr.includes("other")) {
        let other_key_name =
          key === "personal_history"
            ? "other_cancer"
            : key === "comorbidities"
            ? "other_comorbidities"
            : "other_family_conditions";
        this.hormoneZoomerQnr.medical_history[key][other_key_name] = "";
      }
    },
    dateConversion(date) {
      if (!date || !moment(date).isValid()) {
        return;
      };
      this.setLastMenstrualDate(moment(date).format("MM/DD/YYYY"));
    },
    setLastMenstrualDate(val) {
      this.lastMenstrualDate = val;
    }
  },
});
