<template>
  <div id="page-container" v-loading="this.loadingQuestionnaire">
    <div class="pageBodyContainer">
      <QuestionnaireHeader />
      <div class="questionnaireContent">
        <div class="questionnaireDivider mt-[18px] mb-[16px]" />
        <div class="subpartContainer">
          <QuestionnaireRadio
            content="Biological Sex"
            height="tall"
            width="wide"
            :isRequired="true"
            :options="genderOptions"
            :value="this.gender"
          />
        </div>
        <div class="subpartContainer">
          <HeightAndWeightFields />
        </div>
        <div class="subpartContainer" v-if="this.gender == 'Female'">
          <FemaleRelatedQuestions />
        </div>
        <div class="subpartContainer">
          <QuestionnaireRadio
            content="Hormone/medications used in past two months (including steroids):"
            height="short"
            width="wide"
            :isRequired="true"
            :options="yesNoOptions"
            :value="
              hormoneQuestionnaire &&
              hormoneQuestionnaire.hormone_medication_used !== undefined
                ? hormoneQuestionnaire.hormone_medication_used
                : ''
            "
            @onChange="handleHormoneUsedChange"
          />
          <div v-if="hormoneQuestionnaire.hormone_medication_used">
            <HormoneRecord />
          </div>
        </div>
        <div
          class="subpartContainer confirmContainer"
          v-loading="loadingSubmit"
        >
          <el-button class="saveButton" @click="this.saveQuestionnaire">
            Save for Now</el-button
          >
          <el-button
            :disabled="!allFieldsFilled"
            class="submitQuestionnaireButton"
            @click="this.showSubmitDialog = true"
            >Submit</el-button
          >
        </div>
      </div>
      <img id="footerImage" alt="Footer Image" src="@/assets/footerImage.svg" />
    </div>
  </div>
  <el-dialog
    v-model="showSubmitDialog"
    center
    modal-class="hormoneDialog confirmDialog"
  >
    <div class="dialogTitle">Are you sure to submit your questionnaire?</div>
    <div class="dialogContent">
      Results can not be modified after your submission.
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="cancelBtn" @click="this.showSubmitDialog = false"
          >Cancel</el-button
        >
        <el-button class="submitBtn" @click="this.confirmSubmitQuestionnaire">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="showSaveDialog"
    center
    modal-class="hormoneDialog confirmDialog"
  >
    <div class="dialogTitle">
      We have saved your results but haven't submitted.
    </div>
    <div class="dialogContent">
      You can come back here again via email link to continue working on in.
      Please click on <span class="font-medium">"Submit"</span> button once you
      finished this questionnaire.
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="submitBtn" @click="this.showSaveDialog = false">
          Done
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import QuestionnaireHeader from "./QuestionnaireHeader.vue";
import QuestionnaireRadio from "./QuestionnaireRadio.vue";
import HeightAndWeightFields from "./HeightAndWeightFields.vue";
import FemaleRelatedQuestions from "./FemaleRelatedQuestions.vue";
import { genderOptions, yesNoOptions } from "@/utils/questionnaireOptions.js";
import HormoneRecord from "./HormoneRecord.vue";
import { useQuestionnaireStore } from "@/stores/QuestionnaireStore.ts";
import { mapState, mapActions } from "pinia";

export default {
  name: "QuestionnaireView",
  components: {
    QuestionnaireHeader,
    QuestionnaireRadio,
    HeightAndWeightFields,
    FemaleRelatedQuestions,
    HormoneRecord,
  },
  data() {
    return {
      genderOptions,
      yesNoOptions,
      showSaveDialog: false,
      showSubmitDialog: false,
      allFieldsFilled: false,
    };
  },
  async created() {
    try {
      await this.getQuestionnaireData();
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    ...mapState(useQuestionnaireStore, [
      "gender",
      "height",
      "weight",
      "hormoneQuestionnaire",
      "loadingQuestionnaire",
      "loadingSubmit",
    ]),
  },
  watch: {
    height(newValue) {
      if (newValue || newValue === 0) {
        this.updateAllFieldsFilled();
      }
    },
    weight(newValue) {
      if (newValue || newValue === 0) {
        this.updateAllFieldsFilled();
      }
    },
    hormoneQuestionnaire: {
      deep: true,
      handler: function (newValue) {
        if (newValue) {
          this.updateAllFieldsFilled();
        }
      },
    },
  },
  methods: {
    ...mapActions(useQuestionnaireStore, [
      "getQuestionnaireData",
      "setUsedHormoneMedication",
      "submitQuestionnaire",
    ]),
    handleHormoneUsedChange(value) {
      this.setUsedHormoneMedication(value);
    },
    saveQuestionnaire() {
      this.submitQuestionnaire(true);
      this.showSaveDialog = true;
    },
    confirmSubmitQuestionnaire() {
      this.submitQuestionnaire(false);
      this.showSubmitDialog = false;
    },
    updateAllFieldsFilled() {
      let allFields;
      if (this.gender == "Female") {
        allFields = {
          patient_height: this.height,
          patient_weight: this.weight,
          hormone_medication_used:
            this.hormoneQuestionnaire.hormone_medication_used,
          is_currently_pregnant:
            this.hormoneQuestionnaire.is_currently_pregnant,
          is_postmenopausal: this.hormoneQuestionnaire.is_postmenopausal,
          hysterectomy: this.hormoneQuestionnaire.hysterectomy,
          is_ovaries_removed: this.hormoneQuestionnaire.is_ovaries_removed,
          current_menstrual_phase:
            this.hormoneQuestionnaire.current_menstrual_phase,
        };
      } else {
        allFields = {
          patient_height: this.height,
          patient_weight: this.weight,
          hormone_medication_used:
            this.hormoneQuestionnaire.hormone_medication_used,
        };
      }
      this.allFieldsFilled = Object.values(allFields).every(
        (field) => (field !== null && field !== undefined)
      );
    },
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  min-height: 1500px;
  position: relative;
}

.questionnaireContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56vw;
}

.subpartContainer {
  width: 100%;
}

.confirmContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 300px;
}

.saveButton {
  border: 1px solid #0e4672;
  background: #ffffff;
  margin-right: 10px;
  width: 27vw;
  height: 42px;
  color: #0e4672;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.4px;
}

.submitQuestionnaireButton {
  border: 1px solid #0e4672;
  background: #0e4672;
  width: 27vw;
  height: 42px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.4px;
}

.saveButton:hover {
  border: 1px solid #5194d2;
  color: #5194d2;
}

.submitQuestionnaireButton:hover {
  border: 1px solid #5194d2;
  background: #5194d2;
}

.submitQuestionnaireButton.is-disabled {
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
  color: #bbbbbb !important;
}

.dialogTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.46px;
  text-align: center;
  color: #0e4672;
  margin-bottom: 10px;
}

.dialogContent {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.46px;
  text-align: center;
  color: #0e4672;
}

@media only screen and (max-width: 1024px) {
  #footerImage {
    display: none;
  }

  .questionnaireContent {
    width: 90%;
  }

  .confirmContainer {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 50px;
  }

  .saveButton {
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .submitQuestionnaireButton {
    width: 100%;
    margin-left: 0px !important;
    font-size: 12px;
  }
}
@media only screen and (max-width: 808px) {
  .dialogTitle,
  .dialogContent {
    font-size: 16px;
  }

  .cancelBtn,
  .submitBtn {
    width: 30vw !important;
    font-size: 12px !important;
    max-width: 166px;
  }
}
</style>
