<template>
    <div class = "concierge-checkbox-container">
            <el-checkbox v-model="checked">
                <div class = 'bold'>
                    Include a $99 Concierge Blood Draw Service?
                </div>
                <div class = 'regular'>
                    A mobile phlebotomist will contact you, go to your address, collect the sample, and send it back to Vibrant. Smooth and fast service guaranteed!  
                    <!-- <span class = "link">Learn More</span> -->
                </div>
            </el-checkbox>
    </div>
</template>

<script>
export default{
    props:{
        modelValue:{
            type:Boolean,
            default:false,
        }
    },
    data(){
        return {
            checked: this.modelValue
        }
    },
    watch:{
        modelValue(newVal){
            this.checked = newVal;
        },
        checked(newVal){
            this.$emit('update:modelValue', newVal);
        }
    }
}
</script>

<style scoped>
.concierge-checkbox-container {
    width: 56vw;
    margin-top: 12px;
    padding:10px 16px;
    border-radius:8px;
    background-color:#EBF2EF;
}
:deep(.el-checkbox){
    height:fit-content;
    --el-checkbox-input-width: 18px;
    --el-checkbox-input-height: 18px;
    --el-checkbox-input-border: 2px solid #292929;
    --el-checkbox-checked-bg-color: #20A8C3;
    --el-checkbox-checked-input-border-color: #20A8C3;
    align-items: flex-start;
}
:deep(.el-checkbox__input.is-checked .el-checkbox__inner::after){
    transform: rotate(45deg) scaleY(1.5) scaleX(1.5) translate(1px);
}
:deep(.el-checkbox__label){
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.bold{
    width:100%;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    white-space: normal;
    word-break: break-word;
}
.regular{
    width:100%;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    white-space: normal;
    word-break: break-word;
}
.link{
    color: #20A8C3;
    text-decoration: underline;
    cursor:pointer;
}
@media only screen and (max-width: 767px) {
    .concierge-checkbox-container {
        width: 90%;
    }
}

</style>